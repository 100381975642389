import React from "react";
import Modal from "react-modal";
import RotateIcon from "@material-ui/icons/RotateLeft";
import "./photoEditor.css";
import { bindActionCreators } from "redux";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import ToggleButton from "react-toggle-button";
import ImageLoader from "react-loading-image";
import { RingLoader } from "react-spinners";
import { css } from "react-emotion";
import ButtonLoader from "../Helpers/ButtonLoader"
import Save from "@material-ui/icons/Save";

const override = css`
  display: block;
  margin: auto;
  margintop: "150px";
  border-color: red;
`;

const Handle = Slider.Handle;

const handle = props => {
  window.log(props);
  const { value, dragging, index, ...restProps } = props;
  return <Handle value={value} {...restProps} />;
};

const wrapperStyle = { width: "80%", margin: 30 };

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth:"900px",
    borderRadius:"50px"
  }
};

const iconStyle = {
  width: 20,
  height: 20,
  verticalAlign: "middle"
};

const initialFilters = {
  rotate: 0,
  grayscale: false,
  brightness: 0,
  contrast: 0
};

const thumbnailSize = 400;

Modal.setAppElement("#root");

class PhotoEditor extends React.Component {
  constructor() {
    super();

    this.state = {
      filters: initialFilters,
      caption: "",
      width: 0,
      height: 0,
      id: ""
    };

    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.savePhoto = this.savePhoto.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSrc = this.getSrc.bind(this);
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.setState({
      caption: this.props.activePhoto.caption ? this.props.activePhoto.caption : "",
      width: this.props.activePhoto.width,
      height: this.props.activePhoto.height,
      date: this.props.activePhoto.date ? moment(this.props.activePhoto.date) : null,
      filters: initialFilters
    });
  }

  closeModal() {
    this.props.selectPhoto({});
  }

  handleChange(e, filter) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: e
      }
    });
  }

  getSrc(size) {
    return require("../Helpers/getURL")(this.props.activePhoto.src, {
      resize: {
        width: size,
        height: size,
        fit: "inside",
       
      },
      grayscale: this.state.filters.grayscale,
      rotate: this.state.filters.rotate
    });
  }

  addFilter(filter, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: value
      }
    });

    // Prevent rotation angles going over 360
    if (this.state.filters.rotate > 270) this.setState({ filters: { ...this.state.filters, rotate: 0 } });
  }

  updateCaption(event) {
    this.setState({ caption: event.target.value });
  }

  savePhoto() {
    var newPhoto = this.props.activePhoto;
    newPhoto.width = this.state.width;
    newPhoto.height = this.state.height;
    newPhoto.caption = this.state.caption;
    newPhoto.date = this.state.date !== null ? this.state.date.format() : null;
    // Revert height and width
    if (this.state.filters.rotate === 90 || this.state.filters.rotate === 270) {
      newPhoto.width = this.state.height;
      newPhoto.height = this.state.width;
    }
    var self = this;

    this.props.updatePhotos([
      {
        newPhoto: newPhoto,
        newPhotoUrl: this.getSrc(1200),
        wasTransformed: !_.isEqual(initialFilters, this.state.filters)
      }
    ]);

    self.closeModal();
  }

  handleDate(date) {
    this.setState({
      date: date
    });
  }

  render() {
    const canEdit = this.props.activePhoto._addedBy === this.props.auth._id
    const leftPanelWidth = canEdit ? "w-60":"w-100"
    console.log(this.props.activePhoto,this.props.album.photos)
    return (
      <div>
        <Modal
          isOpen={typeof this.props.activePhoto.src !== "undefined"}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <div id="modalContainer" className="pl4">
            <div className={leftPanelWidth+" fl"}>
              <h2>{`${canEdit?"Edit":"View"} your pictures (${_.findIndex(this.props.album.photos, (p) => p.src===this.props.activePhoto.src)+1}/${this.props.album.photos.length})`}</h2>
              {this.props.activePhoto.src && (
                <div>
                  <ImageLoader
                    src={this.getSrc(thumbnailSize)}
                    loading={() => (
                      <div>
                        <RingLoader className={override} color={"#19A974"} sizeUnit={"px"} size={100} />{" "}
                        <p className="tc">Processing...</p>
                      </div>
                    )}
                    error={() => <div>Error</div>}
                    className="center db br3 v-mid"
                  />
                </div>
              )}

              <form className="pt4 black-80">
                <label className="f6 b db mb2">
                  Description{" "}
                  <span className="normal black-60">(optional) {200 - this.state.caption.length} characters left</span>
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  disabled={!canEdit}
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                  aria-describedby="comment-desc"
                  value={this.state.caption}
                  onChange={this.updateCaption}
                  maxlength="200"
                />
                <small id="comment-desc" className="f6 black-60">
                  This comment will be printed near the picture
                </small>
              </form>
            </div>
            {canEdit &&
            <div className="fl w-40">
              <div id="toolbar" className="tc dark-gray bg-light-gray" />
              <div className="tc">
                <fieldset className="mv4 br3">
                  <legend className="f6 b db mb2">Apply filters</legend>
                  <div className="ma2 tc">
                    <p
                      className="b br-pill no-underline pv2 ba white bg-red grow pointer f6 dib ph2"
                      onClick={() => this.addFilter("rotate", this.state.filters.rotate + 90)}
                    >
                      <RotateIcon
                        style={iconStyle}
                        onClick={() => this.addFilter("rotate", this.state.filters.rotate + 90)}
                      />
                    
                      Rotate
                    </p>
                    <div className="dib mh4">
                      <ToggleButton
                        value={this.state.filters.grayscale || false}
                        inactiveLabel="B & W"
                        activeLabel="Color"
                        onToggle={value => {
                          this.addFilter("grayscale", !this.state.filters.grayscale);
                        }}
                        containerStyle={{ width: "100px", height: "40px" }}
                        trackStyle={{ width: "100px" }}
                        thumbAnimateRange={[1, 80]}
                        activeLabelStyle={{ width: "50px" }}
                        inactiveLabelStyle={{ width: "50px" }}
                      />
                    </div>
                  </div>

                  {false && (
                    <div className="ma1">
                      <div style={wrapperStyle}>
                        <p>Brightness</p>
                        <Slider
                          min={0}
                          max={100}
                          defaultValue={0}
                          onAfterChange={e => this.handleChange(e, "brightness")}
                          handle={handle}
                        />
                      </div>
                    </div>
                  )}
                  {false && (
                    <div className="ma1">
                      <div style={wrapperStyle}>
                        <p>Contrast</p>
                        <Slider
                          min={0}
                          max={100}
                          defaultValue={0}
                          onAfterChange={e => this.handleChange(e, "contrast")}
                          handle={handle}
                        />
                      </div>
                    </div>
                  )}
                </fieldset>
                <DatePicker
                  inline
                  minDate={moment().startOf("month")}
                  maxDate={moment().endOf("month")}
                  selected={this.state.date}
                  onChange={this.handleDate}
                />
              </div>
              <div className="tc">
                
                <div className="mt4 dib mh2">
                  <ButtonLoader submitting={false} text="Save" icon={Save} onClick={()=> this.savePhoto()}/>
                </div>
              </div>
            </div>
          }
          </div>
        </Modal>
      </div>
    );
  }
}

export default PhotoEditor;