//import 'materialize-css/dist/css/materialize.min.css';
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import PDFCreator from "./components/PDF/";

if (process.env.NODE_ENV !== "production")
	window.log = text => {
		console.log(text);
	};
else window.log = text => {};

// store
const createStoreWithMiddleware = compose(applyMiddleware(reduxThunk))(createStore);
const store = createStoreWithMiddleware(reducers, {});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				<Route exact path="/pdf" component={PDFCreator} />
				<Route component={App} />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.querySelector("#root")
);