import React, { Component } from "react";
import Alert from "react-s-alert";
import "../../../../node_modules/uppy/dist/uppy.css";
import {fetchUser, fetchRecipients} from '../../../actions' ;
import { connect } from 'react-redux';
import "./avatarpicker.css"
import { createUppy } from "../../Helpers/uppy"


const DashboardModal = require("uppy/lib/react/DashboardModal");


class AvatarPicker extends Component {
  constructor(props) {
    super(props);
    this.state = { avatarUrl: this.props.avatarUrl, modalOpen:false };
    const type = this.props.type
    

    const completeCallback = async (toAdd) => {
      Alert.success("Your avatar was updated !", {
            position: "top-right",
            effect: "slide",
            timeout: 5000
          });
          this.setState({ avatarUrl: toAdd.successful[0].uploadURL, modalOpen:false });
          if (type==="user") this.props.fetchUser();
          else this.props.fetchRecipients();
    }
    this.uppy = createUppy(type, this.props, completeCallback)

  }

  componentDidMount() {
    this.setState({ avatarUrl: this.props.avatarUrl });
    
  }



  render() {
    const avatarUrl = this.state.avatarUrl
    return (
      [<div className="mt3 dib w40 v-top avatar-container">

        <img src={avatarUrl} className="br-100 h3 w3 avatar" alt="avatar" />
        <div class="after br-100 pointer tc" onClick={() => this.setState({modalOpen:true})}><p className="f7" style={{marginTop:'40%'}}>Choose...</p></div>
        </div>,
      <div className="mt3 dib w40 v-mid pv3 mh4">
        <div
          className={this.props.customClass + " ph3"}
          style={{backgroundColor:"rgb(66, 93, 221)"}}
          onClick={() => this.setState({modalOpen:true})}
        >
          Change profile picture
        </div>
        
        <DashboardModal
          uppy={this.uppy}
          plugins={["GoogleDrive", "Instagram", "Dropbox", "Webcam"]}
          open={this.state.modalOpen}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
        />
      </div>]
    );
  }
}

const mapDispatchToProps = {
 fetchUser, fetchRecipients
};

export default connect(null, mapDispatchToProps)(AvatarPicker);
