import React from "react";
import { Link } from "react-router-dom";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import Settings from "@material-ui/icons/Settings";
import Users from "@material-ui/icons/SupervisedUserCircle"
import CreditCard from "@material-ui/icons/CreditCard"


import "./header.css";

var ellipsis = require('text-ellipsis');

const RecipientMenu = props => {
	return (
		
		<ul className="recipientMenu" style={{paddingInlineStart:'0px'}}>
			<li>
				<Link key="recipient" title="recipient" to="/gallery">
					<div className="link white f5 fw5 f7 dib hover-red">
						<p>
							{props.recipient.nickname ? ellipsis(props.recipient.nickname, 12): "Unknown" }
							's
						</p>
						<p className="v-mid">albums <DownArrow className="white v-mid" /></p>
					</div>
					<img
						src={props.recipient.avatar}
						className="dib br-100 ba b--black-10 h2 w2 mh2 "
						alt="avatar"
					/>
					
				</Link>

				<ul className="bg-animate">
					<li className="bg-black-70 absolute ba z-max hover-black menu-item">
						<Link className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 w-100" key="recipient" title="recipient" to="/recipient">
							<Settings className="v-mid"/>
							<span className="v-mid ml2">
								Settings
							</span>
						</Link>
					</li>
					<li className="bg-black-70 absolute ba z-max hover-black menu-item">
						<Link className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 w-100" key="network" title="network" to="/network">
							<Users className="v-mid"/>
							<span className="v-mid ml2">
								Users
							</span>
						</Link>
					</li>
					<li className="bg-black-70 absolute ba z-max hover-black menu-item">
						<Link className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 w-100" key="subscription" title="subscription" to="/subscription">
							<CreditCard className="v-mid"/>
							<span className="v-mid ml2">
								Subscription
							</span>
						</Link>
					</li>
					
					
				</ul>
			</li>
		</ul>
		
	);
};

export default RecipientMenu;