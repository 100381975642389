import axios from "../axios.js";
import {
	FETCH_USER,
	FETCH_ALBUM,
	FETCH_RECIPIENT,
	FETCH_USERS,
	FETCH_RECIPIENT_USER,
	INIT,
	FETCH_INVITES
} from "./types";
import Alert from "react-s-alert";

export const fetchUser = () => async dispatch => {
	const res = await axios.get("/api/current_user");
	return dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchRecipients = () => async dispatch => {
	const res = await axios.get("/api/fetchRecipients");
	dispatch({ type: FETCH_RECIPIENT, payload: res.data });
};

export const fetchUsers = () => async dispatch => {
	const res = await axios.get("/api/fetchUsers");
	dispatch({ type: FETCH_USERS, payload: res.data });
};

export const fetchInvites = () => async dispatch => {
	const res = await axios.get("/api/getInvites");
	dispatch({ type: FETCH_INVITES, payload: res.data });
};

export const init = () => async dispatch => {
	let p1, p2, p3;
	const res = await axios.get("/api/current_user");
	if (res.data) {
		p1 = axios.get("/api/fetchRecipients");
		p2 = axios.get("/api/fetchUsers");
		p3 = axios.get(
			"/api/fetchAlbum/" + new Date().toISOString().slice(0, 7)
		);
		const res2 = await Promise.all([p1, p2, p3]);
		dispatch({
			type: INIT,
			payload: {
				user: res.data,
				recipients: res2[0].data,
				network: res2[1].data,
				album: res2[2].data
			}
		});
	} else dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchAlbum = (
	month = new Date().toISOString().slice(0, 7)
) => async dispatch => {
	const res = await axios.get(`/api/fetchAlbum/${month}`);
	dispatch({ type: FETCH_ALBUM, payload: res.data });
};

export const reorderPhotos = (oldIndex, newIndex) => async dispatch => {
	try {
		const res = await axios.post("/api/reorderPhotos", {
			oldIndex: oldIndex,
			newIndex: newIndex
		});
		dispatch({ type: FETCH_ALBUM, payload: res.data });
		Alert.success("Album order saved", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const updatePhotos = updates => async dispatch => {
	try {
		const res = await axios.post("/api/updatePhotos", updates);
		dispatch({ type: FETCH_ALBUM, payload: res.data });
		Alert.success("Photo saved", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const addPhotos = photos => async dispatch => {
	const res = await axios.post("/api/addPhotos", photos);
	return dispatch({ type: FETCH_ALBUM, payload: res.data });
};

export const deletePhotos = photo => async dispatch => {
	try {
		const res = await axios.post("/api/deletePhotos", photo);
		dispatch({ type: FETCH_ALBUM, payload: res.data });
		Alert.success("Photo deleted", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const subscribe = token => async dispatch => {
	try {
		const res = await axios.post("/api/subscribe", {paymentMethod:token});
		dispatch({ type: FETCH_RECIPIENT, payload: res.data });

		Alert.success("You are now subscribed", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
		return res.data;
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const validateCard = () => async dispatch => {
	try{
		const res = await axios.post("/api/validateCard", {});
		dispatch({ type: FETCH_RECIPIENT, payload: res.data });
	} catch (err) {
	}
}

export const unsubscribe = () => async dispatch => {
	const res = await axios.post("/api/unsubscribe");
	dispatch({ type: FETCH_RECIPIENT, payload: res.data });
};

export const newRecipient = () => async dispatch => {
	try {
		const res = await axios.get("/api/newRecipient");
		const res2 = await axios.get("/api/current_user");

		return dispatch({
			type: FETCH_RECIPIENT_USER,
			payload: { recipients: res.data, user: res2.data }
		});
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const deleteRecipient = () => async dispatch => {
	try {
		const res = await axios.get("/api/deleteRecipient");
		const res2 = await axios.get("/api/current_user");
		Alert.success(
			"This newspaper is now deleted. You are now viewing an other newspaper",
			{
				position: "top-right",
				effect: "slide",
				timeout: 5000
			}
		);
		return dispatch({
			type: FETCH_RECIPIENT_USER,
			payload: { recipients: res.data, user: res2.data }
		});
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const updateCard = token => async dispatch => {
	try {
		const res = await axios.post("/api/updateCard", {paymentMethod:token});
		dispatch({ type: FETCH_RECIPIENT, payload: res.data });

		Alert.success("Your payment information have been updated", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
		return res.data;
	} catch (err) {
		Alert.error(err.response.data, {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
	
};

export function selectPhoto(photo) {
	return {
		type: "PHOTO_SELECTED",
		payload: photo
	};
}

export const login = values => async dispatch => {
	const res = await axios.post("/api/auth/local/login", {
		email: values.email,
		password: values.password
	});

	return true;
};

export const logout = async () => {
	await axios.get("/api/logout");
};

export const changeRecipient = id => async dispatch => {
	try {
		const res = await axios.get("/api/changeRecipient/"+id);
	} catch (err) {
		
	}
};

export const updateRecipient = values => async dispatch => {
	try {
		const res = await axios.post("/api/updateRecipient", {
			values
		});
		dispatch({ type: FETCH_RECIPIENT, payload: res.data });
		Alert.success(values.nickname + "'s profile successfully saved", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	} catch (error) {
		Alert.error(values.nickname + "'s profile could not be updated", {
			position: "top-right",
			effect: "slide",
			timeout: 3000
		});
	}
};

export const getShipping = async (countryCode, quantity, currency) => {
	const cheapest = await axios.get(`/api/getShipping/${countryCode}/${quantity}/${currency}`) 
    return cheapest;
}
export const getPlan = async (countryCode) => {
	const plan = await axios.get(`/api/getPlan`) 
    return plan.data;
} 