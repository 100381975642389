import React from "react"
import { ClipLoader } from "react-spinners";
import { css } from "react-emotion";
import Save from "@material-ui/icons/Save";

const override = css`
  display: block;
  margin-right: 20px;
`;

const $btn =
  "br4 grow w-100 white pv2 input-reset ba bg-transparent pointer f4 tc ttu shadow-5 outline-0";

const ButtonLoader = (props) =>{

  const Icon = props.icon
  	return(
  		<button type="submit" disabled={props.submitting} className={$btn} style={{backgroundColor:'#425DDD'}} onClick={props.onClick}>
          {!Icon && <Save className="mr2 v-btm" />}
          {Icon && <Icon className="mr2 v-btm" />}
            <ClipLoader
              loading={props.submitting}
              className={override}
              sizeUnit={"px"}
              size={15}
              color="#fff"
            />
            {props.text}
          </button>

  	)
  }

 export default ButtonLoader;