import React, { Component } from "react";
import Picker from "react-month-picker";
import PropTypes from "prop-types";
import "react-month-picker/css/month-picker.css";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import CalendarToday from "@material-ui/icons/CalendarToday";

class MonthBox extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      value: this.props.value || "N/A"
    };

    this._handleClick = this._handleClick.bind(this);
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.value) {
      this.setState({
        value: this.props.value || "N/A"
      });
    }
  }

  render() {
    return (
      <div onClick={this._handleClick} >
        <p className="f4 bg-white grow no-underline br-pill ba ph3 pv2 mb2 dib black z-max shadow-5 hover pointer">
          <CalendarToday className="mh2 v-btm" />
          {this.state.value}
          <DownArrow className="mh2 v-btm" />
        </p>
      </div>
    );
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }
}

MonthBox.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default class MonthPicker extends Component {
  constructor() {
    super();
    this.state = {
      mvalue: { year: 2018, month: 11 },
      mrange: { from: { year: 2014, month: 8 }, to: { year: 2015, month: 5 } },
      month: null
    };
    this.handleClickMonthBox = this.handleClickMonthBox.bind(this);
    this.handleAMonthChange = this.handleAMonthChange.bind(this);
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this);
  }

  componentDidUpdate() {
    if (this.props.month != this.state.month) {
      const m = this.props.month;
      const d = m ? m.split("-") : new Date().toISOString().split("-");
      this.setState({
        month: this.props.month,
        mvalue: { year: d[0], month: d[1] }
    });
    }
  }

  componentDidMount() {
    const m = this.props.month;
    const d = m ? m.split("-") : new Date().toISOString().split("-");
    this.setState({
      month: this.props.month,
      mvalue: { year: d[0], month: d[1] }
    });
  }

  handleClickMonthBox(e) {
    this.refs.pickAMonth.show();
  }
  handleAMonthChange(value, text) {
    const month =
      value.toString() +
      "-" +
      text.toString().replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
    this.props.changeMonth(month);
    this.refs.pickAMonth.dismiss();
    this.setState({ mvalue: { year: value, month: text } });
  }
  handleAMonthDissmis(value) {
    //this.setState({ mvalue: value });
  }

  render() {
    const pickerLang = {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      from: "From",
      to: "To"
    };
    const mvalue = this.state.mvalue;
    /*const mrange = this.state.mrange;*/
    window.log(mvalue.month)

    const makeText = m => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + " " + m.year;
      return pickerLang.months[mvalue.month - 1] + " " + mvalue.year;
    };

    return (
      <div>
        <div className="f4 w-30 ">
          <Picker
            ref="pickAMonth"
            years={[2018, 2019]}
            value={mvalue}
            lang={pickerLang.months}
            onChange={this.handleAMonthChange}
            onDismiss={this.handleAMonthDissmis}
          >
            <MonthBox
              value={makeText(mvalue)}
              onClick={this.handleClickMonthBox}
            />
          </Picker>
        </div>
      </div>
    );
  }
}