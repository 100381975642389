import { combineReducers } from 'redux';
import authReducer from './authReducer';
import photoReducer from './photoReducer';
import albumReducer from './albumReducer';
import recipientReducer from './recipientReducer';
import networkReducer from './networkReducer';
import inviteReducer from './inviteReducer'
import { reducer as formReducer } from 'redux-form';


export default combineReducers({
	auth : authReducer,
	activePhoto:photoReducer,
	album:albumReducer,
	form: formReducer,
	recipients:recipientReducer,
	network: networkReducer,
	invites:inviteReducer,
	
});