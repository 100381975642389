import React from "react";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import CreateRecipient from "../Recipient/CreateRecipient";
import { Link } from "react-router-dom";
import New  from "@material-ui/icons/FiberNew";
import "./header.css";

const renderRecipients = props => {
	let result = [];
	result.push(
		<li key="newRecipient" className="bg-black-70 absolute ba z-max hover-black menu-item">
			<Link
				className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 h-100 w-100 pointer ma0"
				key="new-recipient" title="new-recipient" to="/new-recipient"
			>
				<New className="v-mid" />
				<span className="v-mid ml2"> recipient</span>
			</Link>
		</li>
	);
	props.recipients.forEach((r, index) => {
		if (r._id !== props.auth._activeRecipient)
			result.push(
				<li key={index} className="bg-black-70 absolute ba z-max hover-black menu-item">
					<p
						className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 h-100 w-100 pointer ma0"
						title="recipient"
						onClick={() => props.changeRecipient(r._id).then((window.location.href = "/gallery"))}
					>
						<img className="dib br-100 ba b--black-10 h2 w2 v-mid" src={r.avatar} />
						<span className="v-mid ml2 dib">{r.nickname ? r.nickname : "Unknown"}</span>
					</p>
				</li>
			);
	});
	return result;
};

const RecipientList = props => {
	window.log(props);
	return (
		<ul key="recipientMenu" className="recipientMenu">
			<li className="mt2">
				<div className="white f7 hover-red tc v-mid link">
					<p className="pointer">
						Switch to... <DownArrow className="white v-mid" />
					</p>
				</div>

				<ul className="bg-animate">{renderRecipients(props)}</ul>
			</li>
		</ul>
	);
};

export default RecipientList;