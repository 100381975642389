import { FETCH_USER, FETCH_RECIPIENT_USER, INIT } from "../actions/types";

export default function(state = null, action) {
	switch (action.type) {
		case FETCH_USER:
			return action.payload || false;
		case FETCH_RECIPIENT_USER:
		case INIT:
			return action.payload.user || false;
		default:
			return state;
	}
}