import { FETCH_RECIPIENT, FETCH_RECIPIENT_USER, INIT } from '../actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case FETCH_RECIPIENT:
      return action.payload || false;
    case FETCH_RECIPIENT_USER:
    case INIT:
    	return action.payload.recipients || false;
    default:
      return state;
  }
}