import React, { Component } from "react";
import AddUser from "./AddUser";
import _ from 'lodash';
import PersonAdd from "@material-ui/icons/PersonAdd";

class Network extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			recipient: {},
			modalOpen: false
		};
		this.renderUsers = this.renderUsers.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		this.setState({
				users: this.props.network,
				recipient: this.props.recipient
			});

	}

	componentDidUpdate(nextProps){
	window.log(this.props)
	  if(!_.isEqual(this.state.users, this.props.network)){
	    this.setState({users:this.props.network});
	  }
	  if(!_.isEqual(this.state.recipient, this.props.recipient)){
	    this.setState({recipient:this.props.recipient});
	  }
	  
	}
	renderUsers(users, addButton = false, type) {
		let result = [];
		users.forEach(u => {
			result.push(
				<div className="dib mh4">
					<img className="br-pill dib" width={200} src={u.avatar} />
					<p>{u.firstName}{u.nickname}</p>
				</div>
			);
			users[0].nickname ? result.push(<hr/>) : result.push();
		});

		if (addButton)
			result.push(
				<div className="dib mh4 tc" onClick={() => this.setState({modalOpen:true})}>
					<PersonAdd
						className="dib grow no-link pointer green br-pill"
						style={{width:220, height:220}}
					/>
					
				</div>
			);
		return result;
	}

	closeModal(){
		this.setState({modalOpen:false});
	}

	render() {
		switch (this.state.users) {
			case null:
				return <div />;
			default:
				return (
					<section className="vh-40 items-center mv4">
						{this.state.modalOpen && <AddUser onClose={this.closeModal}/> }
						<div className="ph3 w-100 tc">
							{this.renderUsers([this.state.recipient])}
						</div>

						<div className="ph3 mt4 w-100 tc">
							{this.renderUsers(this.state.users, true)}
						</div>
					</section>
				);
		}
	}
}

export default Network;