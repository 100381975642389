import React from "react";
import { Field, reduxForm } from "redux-form";
import {validate} from "../Signup";
import { SubmissionError } from 'redux-form'
import axios from 'axios';
import ButtonLoader from "../../Helpers/ButtonLoader";
import {renderField} from "../../Helpers/renderField";
import ThumbUp from "@material-ui/icons/ThumbUp";

let submitted = false

const submit = async (values, dispatch, props) => {
    try{
      await axios.post('/api/resetpassword', { email:values.email })
      window.location.href="/set-password"
    } catch(error){
      if (error.response.status>200) throw new SubmissionError({ email: "There is no user associated to this email address" });
    }
}

const SubmitValidationForm = props => {
  const { error, handleSubmit, submitting } = props;
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5"
    >
      
      <h1 className="serif tracked ma0 mb4 pv3 f2 tc">Reset my password</h1>
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="email"
          type="text"
          component={renderField}
          label="Email address :"
          placeholder="Enter the email address associated to your account"
        />
        
        {error && <strong>{error}</strong>}
        <div className="mt3">
          <ButtonLoader submitting={submitting} text="Submit" />
          
        </div>
      </form>
    </section>
  );
     
      
      
};

export default reduxForm({
  form: "askReset",
  validate
})(SubmitValidationForm);