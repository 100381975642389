import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./EditRecipient/validate";
import { connect } from "react-redux";
import { deleteRecipient } from "../../actions";
import { bindActionCreators } from "redux";
import "react-widgets/dist/css/react-widgets.css";
import AvatarPicker from "../Auth/Profile/AvatarPicker";
import Alert from "react-s-alert";
import { renderField, renderDrop } from "../Helpers/renderField";
import ButtonLoader from "../Helpers/ButtonLoader";
import Delete from "@material-ui/icons/Delete";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Search from "@material-ui/icons/Search";
import Collapsible from "react-collapsible";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const $btn = "br4 pv2 input-reset no-underline grow pointer dib shadow-5 no-outline";

const $input = "pa2 input-reset ba bg-transparent w-100";
const $label = "db fw6 lh-copy f6 ttu mv2";

function generateCountryList() {
  return require("country-list")
    .getData()
    .map(e => {
      return { label: e.name, value: e.code };
    });
}

const options = generateCountryList();

class CreateRecipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "", rendered: false, collapsed: false };
  }

  handleChange = address => {
    this.setState({ address });
  };

  componentDidMount() {
    const rec = this.props.recipients.find(r => r._id == this.props.auth._activeRecipient);
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth._activeRecipient !== prevProps.auth._activeRecipient) this.setState({ collapsed: false });
  }

  submit = (values, dispatch, props) => {
    console.log(this.props.history)
    this.props.newRecipient().then(r => {
      values.country = values.country.value;
      this.props.updateRecipient(values).then(r1 => {
        if (this.props.history.location.pathname!=="/gallery") window.location.href="/gallery"
      });
    });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        window.log(results);
        this.setState({ collapsed: true });

        if (results[0].address_components.find(r => r.types.includes("route")))
          this.props.change("street", results[0].formatted_address.split(",")[0]);
        if (results[0].address_components.find(r => r.types.includes("postal_code")))
          this.props.change(
            "postalCode",
            results[0].address_components.find(r => r.types.includes("postal_code")).short_name
          );
        if (results[0].address_components.find(r => r.types.includes("locality")))
          this.props.change("city", results[0].address_components.find(r => r.types.includes("locality")).short_name);
        if (results[0].address_components.find(r => r.types.includes("country")))
          this.props.change("country", {
            label: results[0].address_components.find(r => r.types.includes("country")).long_name,
            value: results[0].address_components.find(r => r.types.includes("country")).short_name
          });
        if (results[0].address_components.find(r => r.types.includes("administrative_area_level_1")))
          this.props.change(
            "state",
            results[0].address_components.find(r => r.types.includes("administrative_area_level_1")).short_name
          );
        this.setState({ address: "" });
      })
      .catch(error => console.error("Error", error));
  };

  render() {
    window.log(this.props)    
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    const title = "Who is this for ?";
    const initialValues = this.props.initialValues;
    
    return (
      <section data-name="component-info" className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5">
        <p className="serif tracked ma0 mb4 pv3 f2 tc" style={{ margin: "auto" }}>
          {title}
        </p>

        <form onSubmit={handleSubmit(this.submit)}>
          <Field
            name="nickname"
            type="text"
            component={renderField}
            placeholder="How should we call him/her ?"
            label="Nickname"
          />

          <h3 className="tc">Postal address</h3>
          <Field
            name="fullName"
            type="text"
            component={renderField}
            placeholder="Enter your recipient's full name"
            label="First and last name"
          />

          {!this.state.collapsed && (
            <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="mv4">
                  <input
                    {...getInputProps({
                      placeholder: "Start searching for his address...",
                      className: "location-search-input " + $input
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )}
          {!this.state.collapsed && (
            <p className="tc pointer underline" onClick={() => this.setState({ collapsed: true })}>
              Or click here to manually type...
              <ArrowDropDown className="mh2 v-btm" />
            </p>
          )}
          {this.state.collapsed  && (
            <p className="underline"> Verify that the following address is correct : </p>
          )}
          <Collapsible open={this.state.collapsed} className="tc hover">
            <Field
              name="street"
              type="text"
              component={renderField}
              placeholder="Street"
              customClass="dib w-60 v-top"
              label="Street name and street number"
            />

            <Field
              name="street2"
              type="text"
              component={renderField}
              placeholder="Apartment or unit number"
              customClass="dib mh4 w-30 v-top"
              label="Apartment/Suite/Unit"
            />

            <Field
              name="postalCode"
              type="text"
              component={renderField}
              placeholder="Postal code"
              customClass="dib w-30 v-top"
              label="Postal or ZIP code"
            />
            <Field
              name="city"
              type="text"
              component={renderField}
              placeholder="City"
              customClass="dib w-60 mh4 v-top"
              label="City"
            />
            <Field
              name="state"
              type="text"
              component={renderField}
              placeholder="State or Province"
              customClass="dib w-30 v-top"
              label="State / Province"
            />

            <Field
              name="country"
              component={renderDrop}
              placeholder="Country"
              label="Country"
              customClass="dib w-60 mh4 v-top"
              options={options}
            />
          </Collapsible>
          {error && <strong>{error}</strong>}
          <div className="mt4 tc ">
            <ButtonLoader submitting={submitting} text="Create" />
          </div>
        </form>
      </section>
    );
  }
}

function getInitialValue(state) {
  const rec =
    state.auth && state.auth._activeRecipient && state.recipients.find(r => r._id == state.auth._activeRecipient);
  return {};
}


CreateRecipient = reduxForm({
  form: "CreateRecipient",
  validate,
  enableReinitialize: true
})(CreateRecipient);

CreateRecipient = connect(
  state => ({
    initialValues: getInitialValue(state),
  }),
  null
)(CreateRecipient);

export default CreateRecipient;