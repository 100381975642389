import React from "react";
import Select from "react-select";
import ReactPasswordStrength from "react-password-strength";

const $input = "pa2 input-reset ba bg-white w-100 br2";
const $label = "db fw6 lh-copy f6 ttu mv2";

const renderField = ({ input, label, placeholder, type, customClass, meta: { touched, error } }) => {
	const placeHolder = placeholder ? placeholder : label;
	return (
		<div className={"mt3 " + (customClass ? customClass : "")}>
			<label className={$label}>{label}</label>
			<div>
				<input {...input} placeholder={placeHolder} type={type} className={$input} />
				{touched && error && <p className="red mv2">{error}</p>}
			</div>
		</div>
	);
};

const renderDrop = ({ input, data, customClass, label, value, options }) => {
	return (
		<div className={"mt3 " + (customClass ? customClass : "")}>
			<label className={$label}>{label}</label>
			<Select

				{...input} 
      			onChange={value => input.onChange(value)} 
      			onBlur={() => input.onBlur(input.value)} 
				value={input.value.label? input.value:options.find(o => o.value === input.value)}
				options={options}
				menuPortalTarget={document.querySelector("body")}
			/>
		</div>
	);
};

const renderPassword = ({ input, label, placeholder, type, customClass, meta: { touched, error } }) => {
	const placeHolder = placeholder ? placeholder : label;
	return (
		<div className="mt3">
			<label className="db fw6 lh-copy f6 ttu">{label}</label>
			<ReactPasswordStrength
				className="input-reset ba bg-white w-100 br2"
				minLength={5}
				minScore={2}
				scoreWords={["weak", "okay", "good", "strong", "stronger"]}
				inputProps={Object.assign(
					{
						autoComplete: "off",
						className: "form-control"
					},
					input
				)}
			/>
			{touched && error && <p className="red mv2">{error}</p>}
		</div>
	);
};

const renderCheckbox = ({ input, label, placeholder, type, customClass, meta: { touched, error } }) => {
	const placeHolder = placeholder ? placeholder : label;
	return (
		<div className={"mt3 " + (customClass ? customClass : "")}>
			<input {...input} type="checkbox" />
			<label className="ml2">
				<a
					href="https://app.termly.io/document/terms-and-conditions/c53dd003-680f-4b1e-9152-f47a7b82e3e8"
					target="_blank"
				>
					{label}
				</a>
			</label>
			{touched && error && <p className="red mv2">{error}</p>}
		</div>
	);
};

export { renderField, renderDrop, renderPassword, renderCheckbox };