import React, { Component } from "react";
import "../../../node_modules/uppy/dist/uppy.css";
import Alert from "react-s-alert";
import AfterUpload from "../PhotoEditor/AfterUpload";
import _ from "lodash";
import "./uppy.css";
import { createUppy } from "../Helpers/uppy";
import SmileySad from "./smiley-sad-512.png"
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const Dashboard = require("uppy/lib/react/DashboardModal");

const $btn =
	"br4 grow w-80 white pv2 input-reset ba bg-green bg-transparent pointer f4 tc ttu shadow-5 center";

class NoPhoto extends Component {
	constructor(props) {
		super(props);

		const completeCallback = async toAdd => {
			this.props.onOngoingUpload(true);
			await this.props.addPhotos(toAdd);
			Alert.success(`New photo${toAdd.length > 1 ? "s" : ""} added`, {
				position: "top-right",
				effect: "slide",
				timeout: 3000
			});
			window.location.href="/gallery"
			
		};
		this.uppy = createUppy("photo", this.props, completeCallback);

		this.state = {
			photos: [],
		};
		this.closeModal = this.closeModal.bind(this);
	}

	async closeModal() {
		this.setState({ modalOpen: false });
		window.log("This is called");
		this.props.onOngoingUpload(false);
	}

	componentWillUnmount() {
		this.uppy.close();
	}

	componentDidMount() {
		if (this.props.photos) this.setState({ photos: this.props.photos });
	}

	componentDidUpdate() {
		if (!_.isEqual(this.state.photos, this.props.photos))
			this.setState({ photos: this.props.photos });
	}

	render() {
		
		const title = !this.props.readOnly
			? "Start adding pictures to this month's album"
			: "We couldn't find any pictures for this month";
		//window.log(this.props.readOnly)
		return (
			<div>
				<section className="flex-ns vh-40 items-center mv4">
					<div className="ph3 w-100 tc center">
						<h2 className="f5 f3-l fw1 mb4 mb5-l lh-title">
							{title}
						</h2>

						{!this.props.readOnly && (
							<Dashboard
								uppy={this.uppy}
								plugins={[
									"GoogleDrive",
									"Instagram",
									"Dropbox",
									"Webcam"
								]}
								proudlyDisplayPoweredByUppy={false}
								showLinkToFileUploadResult={false}
								open={this.state.modalOpen}
								onRequestClose={this.handleUploadModalClose}
								inline={true}
								height={300}
								
							/>
						)}
						{this.props.readOnly && (
							<img
								width="300"
								src={SmileySad}
							/>
						)}
						{this.props.readOnly && (
							<p
								style={{ maxWidth: "400px" }}
								onClick={() =>
									this.props.changeMonth(
										new Date().toISOString().slice(0, 7)
									)
								}
								className={$btn}
							>
								<KeyboardBackspace className="v-btm" /> Go back to this month's album
							</p>
						)}

						
					</div>
				</section>
			</div>
		);
	}
}

export default NoPhoto;