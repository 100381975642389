import React, { createRef } from "react";
import { Link } from "react-router-dom";
import logo from "./babushkam.png";
import { isIOS, isAndroid, isMobile } from "react-device-detect";

class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.video = React.createRef();
		this.state = {
			currentStep:0,
			currentText:"Send love every month"
		}
	}

	updateText = (time) => {
		console.log(time)
		const steps = 
			[{
				step:1,
				startingTime:0,
				endingTime:3,
				text:"Send love every month"
			},
			{
				step:2,
				startingTime:3,
				endingTime:6,
				text:"Download the Babushkam app"
			},
			{
				step:3,
				startingTime:5.8,
				endingTime:9.5,
				text:"Create your 1st album"
			},
			{
				step:4,
				startingTime:9.5,
				endingTime:12,
				text:"Invite friends & family"
			},
			{
				step:5,
				startingTime:12,
				endingTime:15.5,
				text:"And start adding pictures"
			},
			{
				step:6,
				startingTime:15.5,
				endingTime:18,
				text:"Up to 50 pictures per month"
			},
			{
				step:7,
				startingTime:18,
				endingTime:20.5,
				text:"We print in at the end of the month"
			},
			{
				step:8,
				startingTime:20.5,
				endingTime:23.5,
				text:"And send it directly to their mailbox"
			},
			{
				step:9,
				startingTime:23.5,
				endingTime:26,
				text:"Wherever they are!"
			},
			{
				step:10,
				startingTime:26,
				endingTime:35,
				text:""
			}
			]
		const currentStep = steps.find(s => (s.startingTime<=time && s.endingTime>time))
		if (currentStep && currentStep.step!==this.state.currentStep) this.setState({currentStep:currentStep.step, currentText:currentStep.text}) 

	}

	render() {
		let link;
		const iosLink = "https://apps.apple.com/us/app/babushkam/id1473681816";
		const androidLink = "https://play.google.com/store/apps/details?id=com.edicated.babushkam";
		if (isMobile && isAndroid) link = androidLink;
		else if (isMobile && isIOS) link = iosLink;
		else link = "/#download";
		return (
			<div>
				<header className="sans-serif ">
					<div style={{ width: "100%", overflow: "hidden", minHeight:"500px", position: "relative" }}>
						<video
							autoPlay
							muted
							ref={this.video}
							loop
							playsInline
							onTimeUpdate={() => this.updateText(this.video.current.currentTime)}
							style={{
								minWidth: "100%",
								minHeight: "100%",
								position: "absolute",
								top: "50%",
								left: "50%",
								zIndex: 0,
								transform: "translateX(-50%) translateY(-50%)"
							}}
						>
							<source src="/backgroundWebsite.mp4" type="video/mp4" />
						</video>
						<div style={{ position:"absolute",backgroundColor:"black",opacity:0.5, width:"100%",height:"500px" }}>
						</div>
						<div className="cover bg-left bg-center-l z-max" style={{ transform: "translateZ(0)" }}>
							<div className="">
								<nav className="dt w-100 mw8 center">
									<div className="dtc v-mid pa3">
										<a href="/#download" className="dib w4 h2 pa1 grow-large ">
											<img src={logo} />
										</a>
									</div>

									<div className="dtc v-mid tr pa3">
										<a
											className="f6 fw4 hover-white no-underline white-70 dib ml2 pv2 ph3 ba"
											href="/#howitworks"
										>
											How it Works
										</a>
										<a
											className="f6 fw4 hover-white no-underline white-70 dib ml2 pv2 ph3 ba"
											href={link}
										>
											Download
										</a>

										{false && (
											<Link
												key="login"
												className="f6 fw4 hover-white no-underline white-70 dib ml2 pv2 ph3 ba"
												title="Login"
												to="/login"
											>
												Login
											</Link>
										)}

										{false && (
											<Link
												key="signup"
												className="f6 fw4 hover-white no-underline white-70 dib ml2 pv2 ph3 ba"
												title="Sign up"
												to="/signup"
											>
												Sign up
											</Link>
										)}
									</div>
								</nav>
								<div className="tc ph3">
									<h1 className="f2 f1-l fw4 white-90 mb0 lh-title tc ttu">
										{this.state.currentText}
									</h1>
										<a
											className="f4 tc no-underline br-pill grow dib v-mid bg-blue white ba b--blue ph3 pv2 mb3 mt6 ttu"
											href={link}
										>
											Get the app now
										</a>
									
								</div>
							</div>
						</div>
					</div>
				</header>
				<section
					id="book"
					className="vh-50 dt black w-100"
					style={{ backgroundColor: "aliceblue" }}
				>
					<div
						className="dtc v-mid center measure tc w-20"
						style={{
							backgroundImage: "url('/images/albumCover.png')",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center"
						}}
					/>
					<div className="dtc v-mid center measure tc w-60">
						<h2 className="f1 ttc">One photo album every month</h2>
						<p>Up to 50 pictures per album. Free shipping worldwide!</p>
						
					</div>
					<div
						className="dtc v-mid center measure tc w-20"
						style={{
							backgroundImage: "url('/images/insideBook.png')",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center"
						}}
					/>
				</section>
				<section id="howitworks" className="mv3 pa0">
					<div className="tc mv4 pa0">
						<h2 className="f3 f1-ns ttc">What you're getting</h2>
						<hr className="mw4 center bn bg-blue" style={{ height: "5px" }} />
					</div>
					<div className="cf w-100 w-80-ns center">
						<div className="fl w-100 w-25-ns tc mb4">
							<img src="/images/Sale_and_Discount.png" style={{ width: "64px", height: "64px" }} />
							<h3 className="f3">Free shipping</h3>
							<p className="black-50 ph3">
								We deliver your album anywhere in the world for free! Tracking is also available for
								some countries.
							</p>
						</div>
						<div className="fl w-100 w-25-ns tc mb4">
							<img src="/images/users-128.png" style={{ width: "64px", height: "64px" }} />
							<h3 className="f3">Unlimited contributors</h3>
							<p className="black-50 ph3">
								Authorize as many people as you want to add pictures. No extra fee!
							</p>
						</div>
						<div className="fl w-100 w-25-ns tc mb4">
							<img src="/images/book_image_pause-128.png" style={{ width: "64px", height: "64px" }} />
							<h3 className="f3">No commitment</h3>
							<p className="black-50 ph3">
								Not enough pictures to share this month? No problem, you can pause or stop anytime.
							</p>
						</div>
						<div className="fl w-100 w-25-ns tc mb4">
							<img src="/images/badge-128.png" style={{ width: "64px", height: "64px" }} />
							<h3 className="f3">High Quality</h3>
							<p className="black-50 ph3">
								Our albums are printed on uncoated matte white paper. One picture per page.
							</p>
						</div>
					</div>
				</section>
				<section
					id="download"
					className="vh-50 dt near-white w-100"
					style={{ backgroundColor: "rgba(0, 34, 212, 0.77)" }}
				>
					<div
						className="dtc v-mid center measure tc w-20"
						style={{
							backgroundImage: "url('/images/smartphones.png')",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center"
						}}
					/>
					<div className="dtc v-mid center measure tc w-60">
						<h2 className="f1 ttc">Add your photos from anywhere</h2>
						<p>Our app is available on any device! Download the app to get started!</p>
						<div>
							<a
								className="dib mb4 mb0-ns pa2"
								href="https://apps.apple.com/us/app/babushkam/id1473681816"
							>
								<img
									style={{ height: "60px" }}
									src="https://cdn.worldvectorlogo.com/logos/download-on-the-app-store-apple.svg"
									alt=""
								/>
							</a>
							<a
								className="dib mb4 mb0-ns pa2"
								href="https://play.google.com/store/apps/details?id=com.edicated.babushkam"
							>
								<img style={{ height: "60px" }} src="/images/google_play.png" alt="" />
							</a>
						</div>
					</div>
					<div
						className="dtc v-mid center measure tc w-20"
						style={{
							backgroundImage: "url('/images/laptop.png')",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center"
						}}
					/>
				</section>
				<section id="contact" class="vh-50 w-100 dt contact">
					<div class="dtc v-mid">
						<h2 className="tc near-black f1 ttc">Let's Stay in Touch!</h2>
						<hr className="mw4 center bn bg-blue" style={{ height: "5px" }} />
						<p className="mw7 center b near-black lh-copy f5 tc">
							Feel free to contact us by email or social media for any question you have!
						</p>
						<div class="pv4 ph3 tc">
							<a
								class="link blue hover-silver dib mh3 tc"
								href="mailto:contact@edicated.com?subject=feedback"
								title="email"
							>
								<img style={{ height: "60px" }} src="/images/mail-128.png" />
								<span class="f5 b db">email us</span>
							</a>
							<a class="link blue hover-silver dib mh3 tc" href="tel:{{site.telephone}}" title="GitHub">
								<span class="f5 b db " />
							</a>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Landing;