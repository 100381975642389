import React, { Component } from "react";
import Iframe from "react-iframe";
import Modal from "react-modal";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			url: null
		};
	}

	openModal = url => {
		this.setState({ url: url, modalOpen: true });
	};

	closeModal = () => {
		this.setState({ modalOpen: false });
	};
	render() {
		return (
			<footer class="pv4 ph3 ph5-m ph6-l white" style={{ backgroundColor: "#0022d4c4" }}>
				{this.state.modalOpen && <Modal
					isOpen={true}
					onRequestClose={this.closeModal}
					style={{
						content: {
							top: "50%",
							left: "50%",
							right: "auto",
							bottom: "auto",
							marginRight: "-50%",
							transform: "translate(-50%, -50%)",
							width: "80%",
							height:"80%",
							maxWidth: "900px",
							borderRadius: "50px",
							overflow:"hidden"
						}
					}}
				>
					<Iframe
						url={this.state.url}
						width="100%"
						height="100%"
						id="myId"
						display="initial"
						position="relative"
					/>
				</Modal>}
				<small class="f6 db tc">©{new Date().getFullYear()} Edicated B.V.</small>
				<div class="tc mt3 ">
					<a
						class="link dim white dib h2 w2 br-100 mr3 "
						href="https://www.facebook.com/BabushkamApp"
						title=""
					>
						<svg data-icon="facebook" viewBox="0 0 32 32" style={{ fill: "currentcolor" }}>
							<title>facebook icon</title>
							<path d="M8 12 L13 12 L13 8 C13 2 17 1 24 2 L24 7 C20 7 19 7 19 10 L19 12 L24 12 L23 18 L19 18 L19 30 L13 30 L13 18 L8 18 z" />
						</svg>
					</a>
					<a className="link dim white dib h2 w2 br-100 mr3 " href="#" title="">
						<svg data-icon="twitter" viewBox="0 0 32 32" style={{ fill: "currentcolor" }}>
							<title>twitter icon</title>
							<path d="M2 4 C6 8 10 12 15 11 A6 6 0 0 1 22 4 A6 6 0 0 1 26 6 A8 8 0 0 0 31 4 A8 8 0 0 1 28 8 A8 8 0 0 0 32 7 A8 8 0 0 1 28 11 A18 18 0 0 1 10 30 A18 18 0 0 1 0 27 A12 12 0 0 0 8 24 A8 8 0 0 1 3 20 A8 8 0 0 0 6 19.5 A8 8 0 0 1 0 12 A8 8 0 0 0 3 13 A8 8 0 0 1 2 4" />
						</svg>
					</a>
				</div>
				<div class="tc mt3 ">
					<p
						onClick={() =>
							this.openModal(
								"https://app.termly.io/document/terms-and-conditions/c53dd003-680f-4b1e-9152-f47a7b82e3e8"
							)
						}
						className="f6 hover dib ph2 link dim white underline"
					>
						Terms of Use
					</p>
					<p
						onClick={() => this.openModal("https://www.iubenda.com/privacy-policy/50436890")}
						className="f6 hover dib ph2 link dim white underline"
					>
						Privacy policy
					</p>
				</div>
			</footer>
		);
	}
}

export default Footer;