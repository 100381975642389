import React from "react";
import { Field, reduxForm } from "redux-form";
import {
  FacebookLoginButton,
  GoogleLoginButton
} from "react-social-login-buttons";
import { SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import axios from "axios";
import ButtonLoader from "../../Helpers/ButtonLoader";
import {renderField, renderPassword, renderCheckbox} from "../../Helpers/renderField";
import "./index.css";



const submit = async (values, dispatch, props) => {
  try {
    await axios.post("/api/auth/local/signup", {
      email: values.email,
      password: values.password,
      firstName: values.firstName
    });
    
    
  } catch (error) {
    window.log(error.response)
    if (!error.response.data.success)
      throw new SubmissionError({
        email: error.response.data.message,
      });
  }
  await props.login(values)
  window.location.href="/gallery"
};




export const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Choose a password";
  }
  if (!values.firstName) {
    errors.firstName = "Required - This can also be a nickname";
  }
  if (values.password !== values.password2) {
    window.log(values.password)
    errors.password2 = "Passwords do not match";
  }
  if (!values.tos) {
    errors.tos = "You must agree with the terms and conditions";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const SubmitValidationForm = props => {
  window.log(props);
  const { error, handleSubmit, submitting } = props;
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5"
    >
      <div className="mt4 tc">
        <div className="dib tc">
          <a className="link" href={process.env.REACT_APP_API_URL+"/api/auth/google"}>
            <GoogleLoginButton size="40px">
              <span className="f6">Register with Google</span>
            </GoogleLoginButton>
          </a>
        </div>
        <div className="dib tc">
          <a className="link" href={process.env.REACT_APP_API_URL+"/api/auth/facebook"}>
            <FacebookLoginButton size="40px">
              <span className="f6">Register with Facebook</span>
            </FacebookLoginButton>
          </a>
        </div>
      </div>
      <hr className="mv4" />
      <h1 className="serif tracked ma0 mb4 pv3 f2 tc">Or Create A New Account</h1>
      <form onSubmit={handleSubmit(submit)}>
        <Field name="email" type="text" component={renderField} label="Email" />
        <Field
          name="firstName"
          type="text"
          component={renderField}
          label="First name"
        />
        <Field
          name="password"
          type="password"
          component={renderPassword}
          label="Password"
        />

        <Field
          name="password2"
          type="password"
          component={renderField}
          label="Confirm password"
        />
        {error && <strong>{error}</strong>}
        <div className="mv4 tc">
          <Field
            name="tos"
            label="I agree with the terms and conditions"
            component={renderCheckbox}
          />
        </div>
        <div className="mt3">
          <ButtonLoader submitting={submitting} text="Register" />
        </div>
        <div className="mv4 tc">
          <Link className="i" title="Login" to="/login">
            Aready have an account ? Login now!
          </Link>
        </div>
      </form>
    </section>
  );


};

export default reduxForm({
  form: "Signup",
  validate
})(SubmitValidationForm);