import React, { Component } from "react";
import "../../../node_modules/uppy/dist/uppy.css";
import Alert from "react-s-alert";
import AfterUpload from "../PhotoEditor/AfterUpload";
import { createUppy } from "../Helpers/uppy";
import upload from './upload-512.png'

const DashboardModal = require("uppy/lib/react/DashboardModal");

class AddPhoto extends Component {
  constructor(props) {
    super(props);

    const completeCallback = async toAdd => {
      await this.props.addPhotos(toAdd);
      this.props.history.push("/gallery");
      this.handleUploadModalClose();
      Alert.success(`New photo${toAdd.length > 1 ? "s" : ""} added`, {
        position: "top-right",
        effect: "slide",
        timeout: 3000
      });
      this.setState({
        uploadResult: toAdd,
        uploadDone: true,
        postModalOpen: true
      });
    };
    this.uppy = createUppy("photo", this.props, completeCallback);

    this.state = {
      modalOpen: false,
      uploadDone: false,
      uploadResult: [],
      postModalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
  }

  handleUploadModalOpen = () => {
    this.setState({ modalOpen: true });
  };
  handleUploadModalClose = () => this.setState({ modalOpen: false });

  componentWillUnmount() {
    this.uppy.close();
  }

  closeModal() {
    this.setState({ postModalOpen: false });
  }

  render() {
    const afterUpload = (
      <AfterUpload
        onClose={this.closeModal}
        isOpen={this.state.uploadDone}
        newPhotos={this.state.uploadResult}
        {...this.props}
      />
    );
    return (
      <div className="dib ml2 fr">
        <a
          key="addPhoto"
          onClick={this.handleUploadModalOpen}
          href="#"
          className="br-pill grow pr2 no-underline near-white bg-animate bg-gray hover-bg-gray inline-flex items-center tc br2"
        >
          <img
            width="32"
            src={upload}
          />
          {/*<svg class="dib h2 w2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414"><g id="Expanded"><g><g><path d="M44,39h-6.402v-2H44c1.103,0,2-0.897,2-2v-4.734l-4-7V7c0-2.757-2.243-5-5-5H11C8.243,2,6,4.243,6,7v16.266l-4,7V35     c0,1.103,0.897,2,2,2h6.305v2H4c-2.206,0-4-1.794-4-4v-5.266l4-7V7c0-3.86,3.14-7,7-7h26c3.86,0,7,3.14,7,7v15.734l4,7V35     C48,37.206,46.206,39,44,39z"/></g><g><path d="M45.762,48H2.586l1.707-1.707C9.905,40.68,10,34.064,10,33.998l0.004-0.995L11,33h26.997L38,33.999     c0,0.076,0.087,7.605,5.64,12.233L45.762,48z M7.25,46h33.299c-3.52-4.021-4.316-8.92-4.497-11H11.937     C11.734,36.976,10.875,41.55,7.25,46z"/></g><g><path d="M24,18.9c-3.805,0-6.9-3.095-6.9-6.9s3.095-6.9,6.9-6.9s6.9,3.095,6.9,6.9S27.805,18.9,24,18.9z M24,6.9     c-2.812,0-5.1,2.288-5.1,5.1s2.288,5.1,5.1,5.1s5.1-2.288,5.1-5.1S26.812,6.9,24,6.9z"/></g><g><path d="M11.5,20.062c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5S12.327,20.062,11.5,20.062z M11.5,18.062     c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5S11.776,18.062,11.5,18.062z"/></g><g><path d="M40,11.062h-7v-6h7V11.062z M35,9.062h3v-2h-3V9.062z"/></g><g><rect height="5.342" width="2" x="21" y="1"/></g><g><rect height="5.342" width="2" x="25" y="1"/></g><g><rect height="5.343" width="2" x="21" y="17.657"/></g><g><rect height="5.343" width="2" x="25" y="17.657"/></g><g><rect height="2" width="38" x="5" y="22"/></g><g><rect height="2" width="46" x="1" y="29.062"/></g><g><rect height="2" width="40" x="4" y="33"/></g><g><rect height="2" width="31.474" x="8.141" y="42"/></g></g></g></svg>*/}
          <span className="f7 ml3 pr2">Upload</span>
        </a>
        <DashboardModal
          uppy={this.uppy}
          plugins={["GoogleDrive", "Instagram", "Dropbox", "Webcam"]}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          open={this.state.modalOpen}
          onRequestClose={this.handleUploadModalClose}
        />
        
      </div>
    );
  }
}

export default AddPhoto;