import React, { Component } from "react";
import SmileySad from "./Gallery/smiley-sad-512.png"
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Warning from "@material-ui/icons/Warning";


const $btn =
	"br4 grow w-80 white pv2 input-reset ba bg-green bg-transparent pointer f4 tc ttu shadow-5 center";
const $btn2 =
	"br4 w-80 white pv2 input-reset ba bg-red bg-transparent pointer f4 tc ttu shadow-5 center";

class NoMatching extends Component {
	

	render() {
		
		return (
			<div>
				<section className="flex-ns vh-40 items-center mv4">
					<div className="ph3 w-100 tc center">
							<p
								style={{ maxWidth: "400px" }}
								className={$btn2}
							>
								<Warning className="v-btm" /> This page doesn't exist!
							</p>

							<img
								width="300"
								src={SmileySad}
							/>
						
							<p
								style={{ maxWidth: "400px" }}
								onClick={() =>
									this.props.history.push("/")
								}
								className={$btn}
							>
								<KeyboardBackspace className="v-btm" /> Go back to the homepage
							</p>
						

					</div>
				</section>
			</div>
		);
	}
}



export default NoMatching