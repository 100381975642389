import { FETCH_ALBUM, INIT } from '../actions/types';

export default function(state = {photos:[]}, action) {
  switch (action.type) {
    case FETCH_ALBUM:
      return action.payload || false;
    case INIT:
	  return action.payload.album || false
    default:
      return state;
  }
}