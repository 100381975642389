import { SubmissionError } from "redux-form";
import axios from "axios";
import Alert from "react-s-alert";

function submit(values, dispatch, props) {
	window.log(values);
	values.country = values.country.value;
	values.language = values.language.value;
	window.log(values);
	return axios
		.post("/api/updateRecipient", { values: values })
		.then(response => {
			Alert.success("Profile updated", {
				position: "top-right",
				effect: "slide",
				timeout: 5000
			});
			props.fetchRecipients();
			props.history.push("/gallery");
		})
		.catch(err => {
			if (err.response.status === 401)
				Alert.error(err.response.data, {
					position: "top-right",
					effect: "slide",
					timeout: 5000
				});
		});
}

export default submit;