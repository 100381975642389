import React from "react";
import { RingLoader } from "react-spinners";
import { css } from "react-emotion";

const override = css`
	display: block;
	margin: 20vh auto 2vh auto;
	border-color: red;
`;


const ActivityIndicator = props => {
	if (!props.withoutBackground)
		return (
			
				
				<div data-name="component-info" style={{ minHeight: "60vh" }}>
					<RingLoader
						className={override}
						color={"#19A974"}
						sizeUnit={"px"}
						size={100}
					/>
					<p className="tc">{props.label ? props.label : "Loading..."}</p>
				</div>

		);
	else
		return (
			<div>
				<RingLoader
					className={override}
					color={"#19A974"}
					sizeUnit={"px"}
					size={100}
				/>
				<p className="tc">{props.label ? props.label : "Loading..."}</p>
			</div>
		);
};

export default ActivityIndicator;