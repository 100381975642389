import React, { Component } from "react";

import UserMenu from "./UserMenu";
import RecipientMenu from "./RecipientMenu";
import RecipientList from "./RecipientList";
import { Link } from "react-router-dom";
import AddPhoto from "../Uploader/AddPhoto";
import PropTypes from "prop-types";
import Counter from "./Counter";
import polaroid from "./polaroids-512.png";
import card from "./creditcard-512.png";

class Header extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = { avatar: this.props.auth.avatar };
	}

	renderContent() {
		const avatar = this.state.avatar;
		switch (this.props.auth) {
			case null:
				return null;
			case false:
				return (
					<div className="flr mh4 dib v-mid pv3" style={{ marginLeft: "auto" }}>
						<Link
							key="login"
							className="ml4 f6 dib white bg-animate hover-bg-white hover-black no-underline pv2 ph4 br-pill ba b--white-20"
							title="Login"
							to="/login"
						>
							Login
						</Link>
						<Link
							key="signup"
							className="ml4 f6 dib white bg-animate hover-bg-white hover-black no-underline pv2 ph4 br-pill ba b--white-20"
							title="Sign up"
							to="/signup"
						>
							Sign up
						</Link>
					</div>
				);

			default:
				const rec = this.props.recipients.find(
					r => r._id.toString() === this.props.auth._activeRecipient.toString()
				);

				const addPicture = rec ? <AddPhoto {...this.props} /> : undefined;
				let subscription = null;
				if (rec) {
					subscription = !rec.subscriptionId ? (
						<Link key="subscribe" className="no-underline fr" title="subscription" to="/subscription">
							<div className="br-pill grow ml3 no-underline near-white bg-animate bg-gray hover-bg-gray inline-flex items-center tc br2">
								<img alt="creditCard" className="v-mid" width="32" src={card} />
								<span className="ph2 white f6 dib">Subscribe</span>
							</div>
						</Link>
					) : (
						<Counter {...this.props} />
					);
				}

				return [
					<div key="1" style={{ marginRight: "auto" }}>
						<ul className="mt3 flex">
							<li key="recipientMenu" style={{ listStyle: "none" }}>
								{rec && <RecipientMenu recipient={rec} {...this.props} />}
							</li>
							<li key="recipientList" style={{ listStyle: "none" }}>
								{rec && <RecipientList {...this.props} />}
							</li>
						</ul>
					</div>,
					<div className="tc center" key="2">
						{rec && <Link className="tc" key="gallery" title="gallery" to="/gallery">
							<img alt="polaroid" className="v-mid" width="32" src={polaroid} />
							<span className="ml3 link white f5 fw5 dib hover-red ">Photos</span>
						</Link>}
					</div>,
					<div key="3" style={{ marginLeft: "auto" }}>
						{addPicture}
						{subscription}
					</div>,
					<div key="4" className="">
						<ul>
							<li style={{ listStyle: "none" }}>
								<UserMenu {...this.props} avatar={avatar} />
							</li>
						</ul>
					</div>
				];
		}
	}

	componentDidUpdate() {
		if (this.props.auth.avatar !== this.state.avatar) {
			this.setState({ avatar: this.props.auth.avatar });
		}
	}

	render() {
		return (
			<nav className="flex bb b--white-10 ttu" style={{ backgroundColor: "#0022d4c4", minHeight:"80px" }}>
				<div className="flex-grow w-100 flex items-center">{this.renderContent()}</div>
			</nav>
		);
	}
}

export default Header;