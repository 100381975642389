import * as axios from 'axios';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    window.log("Interceptor," ,JSON.stringify(error))
    if ( error.response && 503 === error.response.status ) {
      window.location.href = "/maintenance.html";
    }
    return Promise.reject(error);
  }
);

export default axios;