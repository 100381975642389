import React from "react";
import { CardElement, StripeProvider, Elements, injectStripe } from "react-stripe-elements";
import "./payment.css";
import { css } from "react-emotion";
import { BarLoader } from "react-spinners";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import * as actions from "../../actions/index";
const $btn = "br-pill pv2 white w-100 no-underline grow pointer dib tc shadow-5";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = props => {
  return (
    <div className={props.loading ? "sweet-loading mv2 " : "sweet-loading mv2 dn"}>
      <p className="tc">Processing...</p>
      <BarLoader sizeUnit={"%"} size={90} color={"#19A974"} loading={props.loading} className={override} />
    </div>
  );
};

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, checked: false };
  }

  submit = ev => {
    ev.preventDefault();
    this.setState({ loading: true }, () => {
      const action = this.props.updatingPayment ? this.props.updateCard : this.props.subscribe;
      this.props.stripe.createPaymentMethod("card").then(payload => {
        action(payload.paymentMethod).then(res => {
          this.setState({ loading: false }, () => this.props.updatePayment(false));
        });
      });
    });
  };

  render() {
    console.log(this.props);
    return (
      <div>
        <div className={this.state.loading ? " dn" : ""}>
          <p className="mh4">
            {!this.props.updatingPayment &&
              "Once your subscription is activated we will send the monthly album by mail at the start of each month"}
            {this.props.updatingPayment && "Enter your new card information :"}
          </p>
          <form onSubmit={this.submit}>
            <CardElement />
            {!this.state.loading && (
              <div className="mh2">
                <input
                  type="checkbox"
                  defaultChecked={this.state.checked}
                  onChange={() => this.setState({ checked: !this.state.checked })}
                />
                <label className="ml2">
                  <a
                    href="https://app.termly.io/document/terms-and-conditions/c53dd003-680f-4b1e-9152-f47a7b82e3e8"
                    target="_blank"
                  >
                    I agree with the terms and conditions
                  </a>
                </label>
              </div>
            )}
            {!this.state.loading && (
              <button
                className={"mv4 bg-green " + $btn + (this.state.checked ? "" : " o-10")}
                disabled={!this.state.checked}
                type="submit"
              >
                {!this.props.updatingPayment &&
                  "Subscribe for " +
                    new Intl.NumberFormat(this.props.plan.metadata.lang, {
                      style: "currency",
                      currency: this.props.plan.currency
                    }).format(this.props.plan.amount / 100) +
                    " a month"}
                {this.props.updatingPayment && "Update my payment information"}
              </button>
            )}
          </form>
          {!this.state.loading && this.props.recipient.subscriptionId && (
            <p className={"bg-red " + $btn} onClick={() => this.props.updatePayment(false)}>
              Go back
            </p>
          )}
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}
let CardForm = injectStripe(_CardForm);

class Checkout extends React.Component {
  render() {
    return (
      <div className="Checkout">
        <Elements>
          <CardForm {...this.props} />
        </Elements>
      </div>
    );
  }
}

const NotPaying = props => {
  return (
    <p className="mh2">
      Lucky you! You're not the one paying for this subscription. If you would like to be the one paying you just need
      to ask the paying user to cancel his subscription first. Then you will be able to enter youir payment information.
    </p>
  );
};

class _Paying extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.submit = this.submit.bind(this);
  }

  submit() {
    this.setState({ loading: true }, () => {
      this.props.unsubscribe().then(() => this.setState({ loading: false }));
    });
  }

  componentDidMount() {
    if (this.props.recipient.paymentCard && this.props.recipient.paymentCard.pending_setup_intent)
      this.props.stripe.handleCardSetup(this.props.recipient.paymentCard.pending_setup_intent).then(async res => {
        if (res.error) await this.props.unsubscribe();
        else await this.props.validateCard();
      });
  }

  render() {
    return (
      <div className="mh2">
        <p>
          You can pause or cancel your subscription anytime. We will not erase your picture so you can re-enable it at
          anytime !
        </p>
        <div className={this.state.loading ? " dn" : ""}>
          <p onClick={() => this.props.updatePayment(true)} className={"bg-green " + $btn}>
            Change my payment method
          </p>
          <p onClick={() => this.submit()} className={"bg-red " + $btn}>
            Cancel my subscription
          </p>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

let Paying = injectStripe(_Paying);

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { updatingPayment: false, plan: null };
  }

  async componentDidMount() {
    window.log(this.props);
    this.setState({ plan: await actions.getPlan() });
  }

  updatePayment = status => {
    this.setState({ updatingPayment: status });
    window.log(this.state);
  };

  render() {
    let title = this.props.recipient.subscriptionId ? "Your subscription is enabled" : "Activate your subscription";
    if (this.props.recipient.subscriptionId && this.state.updatingPayment) title = "Update your card";
    if (this.props.recipient.paymentCard && this.props.recipient.paymentCard.pending_setup_intent)
      title = "Validate your card";
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <section data-name="component-info" className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5">
          <h1 className="serif tracked ma0 mb4 pv3 f2 tc">{title}</h1>

          <div className="w-100">
            <div className="columns dib v-mid">
              {this.props.recipient.subscriptionId && (
                <Cards
                  number={"**** **** ****" + this.props.recipient.paymentCard.last4}
                  name={this.props.auth.firstName}
                  expiry={this.props.recipient.paymentCard.exp_month + "/" + this.props.recipient.paymentCard.exp_year}
                  cvc="XXX"
                  preview={true}
                  issuer={this.props.recipient.paymentCard.brand.toLowerCase()}
                />
              )}

              {!this.props.recipient.subscriptionId && this.state.plan && (
                <ul className="price">
                  <li className="header">Printed album</li>
                  <li className="grey">
                    Only{" "}
                    {new Intl.NumberFormat(this.state.plan.metadata.lang, {
                      style: "currency",
                      currency: this.state.plan.currency
                    }).format(this.state.plan.amount / 100)}{" "}
                    a month
                  </li>
                  <li>1 album delivered every month with up to 50 photos!</li>
                  <li>Free shipping worldwide</li>
                  <li>Pause or cancel anytime</li>
                  <li>Unlimited contributors</li>
                </ul>
              )}
            </div>
            <div className="dib w-50 v-mid">
              {((!this.props.recipient.subscriptionId && this.state.plan) || this.state.updatingPayment) && (
                <Checkout
                  plan={this.state.plan}
                  updatingPayment={this.state.updatingPayment}
                  updatePayment={status => this.updatePayment(status)}
                  {...this.props}
                />
              )}
              {this.props.recipient.subscriptionId &&
                !this.state.updatingPayment &&
                this.props.recipient._userPaying === this.props.auth._id && (
                  <Elements>
                    <Paying updatePayment={status => this.updatePayment(status)} {...this.props} />
                  </Elements>
                )}
              {this.props.recipient.subscriptionId && this.props.recipient._userPaying !== this.props.auth._id && (
                <NotPaying />
              )}
            </div>
          </div>
        </section>
      </StripeProvider>
    );
  }
}

export default Payment;