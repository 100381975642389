import React from "react";
import Timer from "@material-ui/icons/Timer";
import Photo from "@material-ui/icons/PhotoCamera";


function getMonthDaysLeft(month){

    const date = new Date();
    if (month !== date.toISOString().slice(0, 7)) return 0
    else return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate();
}

const Counter = (props) => {

  return (
    <div className="f7 white fr mr4">
      <div className="v-mid"><Photo className="v-mid" style={{fontSize:'16px'}}/><span className="v-mid ml2 tc">{props.album.photos.length}/{props.album.limit}</span></div>

      <div className="v-mid mt2"><Timer className="v-mid" style={{fontSize:'16px'}}/><span className="v-mid ml2 tc">{getMonthDaysLeft(props.album.month).toString()} days</span></div>
    </div>
  );
}



export default Counter;