import React from "react";
import { Link } from "react-router-dom";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import Exit from "@material-ui/icons/ExitToApp";
import Face from "@material-ui/icons/Face";
import { logout } from "../../actions"
var ellipsis = require('text-ellipsis');

const UserMenu = props => {
	return (
		
		<ul className="recipientMenu" style={{paddingInlineStart:'0px'}}>
			<li>
				<Link key="profile" title="profile" to="/profile" className="no-underline">
					<span className="link white f5 fw5 f7 hover-red ">
						{ellipsis(props.auth.firstName, 10)}
					</span>
					<img
						src={props.avatar}
						className=" dib br-100 ba b--black-10 h2 w2 mh2 v-mid"
						alt="avatar"
					/>
					<DownArrow className="white v-mid" />
				</Link>

				<ul>
					<li className="bg-black-70 absolute ba z-max hover-black menu-item">
						<Link className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 w-100" key="profile" title="profile" to="/profile">
							<Face className="v-mid"/>
							<span className="v-mid ml2">
								My profile
							</span>
						</Link>
					</li>
					<li className="bg-black-70 absolute ba z-max hover-black menu-item">
						<p className="f6 dib white no-underline hover-bg-white hover-black ph4 pv2 h-100 w-100 pointer ma0" onClick={async () => {await logout(); window.location.href="/"}} >
							<Exit className="v-mid"/>
							<span
								key="3"
								className="v-mid ml2"
							>
								Log out
							</span>
						</p>
					</li>
					
				</ul>
			</li>
		</ul>
	);
};

export default UserMenu;