import React from "react";
import { Field, reduxForm } from "redux-form";
import {
  FacebookLoginButton,
  GoogleLoginButton
} from "react-social-login-buttons";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import ButtonLoader from "../../Helpers/ButtonLoader"
import {renderField} from "../../Helpers/renderField";


const submit = async (values, dispatch, props) => {
  try {
    await props.login(values);  
    window.location.href = "/gallery";
  } catch (error) {
    window.log(error)
    if (error.response.status === 401)
      throw new SubmissionError({
        _error: "Login failed! Check if your username and password are correct"
      });
  }
};

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Enter your password";
  }
  if (!values.email) {
    errors.email = "Enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};



const SubmitValidationForm = props => {
  const { error, handleSubmit, submitting } = props;
  document.title = "Babushkam - Login"
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5"
    >
      <div className="mt4 tc">
        <div className="dib tc">
          <a className="link" href={process.env.REACT_APP_API_URL+"/api/auth/google"}>
            <GoogleLoginButton size="40px">
              <span className="f6">Login with Google</span>
            </GoogleLoginButton>
          </a>
        </div>
        <div className="dib tc">
          <a className="link" href={process.env.REACT_APP_API_URL+"/api/auth/facebook"}>
            <FacebookLoginButton size="40px">
              <span className="f6">Login with Facebook</span>
            </FacebookLoginButton>
          </a>
        </div>
      </div>
      <hr className="mv4" />
      <p className="serif tracked ma0 mb4 pv3 f2 tc">Log Into My Account</p>
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="email"
          type="text"
          component={renderField}
          label="Email address"
        />
        <Field
          name="password"
          type="password"
          component={renderField}
          label="Password"
        />
        {error && <p className="red tc"><strong>{error}</strong></p>}
        <div className="mt3">
          <ButtonLoader submitting={submitting} text="Sign in" />
          <div className="mv4 tc">
            <Link className="i" title="Forgot password" to="/reset-password">
              Forgot your password ? Click here
            </Link>
            <hr className="mv4" />
            <Link className="i" title="Forgot password" to="/signup">
              Don't have an account ? Signup now!
            </Link>
          </div>

          <div className="mv4" />
        </div>
      </form>
    </section>
  );
};

export default reduxForm({
  form: "login", // a unique identifier for this form
  validate
})(SubmitValidationForm);