import React from "react";
import Modal from "react-modal";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import Alert from "react-s-alert";
import {renderField} from "../../Helpers/renderField";
import ButtonLoader from "../../Helpers/ButtonLoader"
import Email from "@material-ui/icons/Email";


const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "900px",
		padding: "50px"
	}
};

const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Invalid email address"
		: undefined;

Modal.setAppElement("#root");

class AddUser extends React.Component {
	constructor() {
		super();
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	afterOpenModal() {
		// references are now sync'd and can be accessed.
	}

	closeModal() {
		this.props.onClose();
	}

	render() {
		window.log(this.props);
		const { handleSubmit, submitting } = this.props;

		const submit = async (values, dispatch, props) => {
			try {
			  const res = await axios.post("/api/inviteUser", { email: values.email });
			  Alert.success(res.data, {
				position: "top-right", effect: "slide", timeout: 5000 });
			} 
			catch(err) {
				Alert.error(err.response.data, {
				position: "top-right", effect: "slide", timeout: 5000 });
			}
			
			this.closeModal();
			
		};

		return (
			<div>
				<Modal
					isOpen={true}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
				>
					<form onSubmit={handleSubmit(submit)}>
						<Field
							name="email"
							type="text"
							component={renderField}
							label="Email"
							placeholder="An invite will be sent to this email address"
							validate={email}
							customClass="mv4"
						/>
						<ButtonLoader submitting={submitting} text="Send invite" icon={Email} />

							
					</form>
				</Modal>
			</div>
		);
	}
}

AddUser = reduxForm({
	form: "addUser"
})(AddUser);

export default AddUser;