import React from "react";
import { Field, reduxForm } from "redux-form";
import {validate} from "../Signup";
import { SubmissionError } from 'redux-form'
import axios from 'axios';
import ButtonLoader from "../../Helpers/ButtonLoader"
import {renderField} from "../../Helpers/renderField";
import ThumbUp from "@material-ui/icons/ThumbUp";

const submit = (values, dispatch, props) => {
  
    return axios.post('/api/setpassword', { password:values.password, token:values.token, email:values.email })
        .then((response) => {
          props.history.push('/login')
        })
        .catch(error => {
          if (error.response.status>200) throw new SubmissionError({ _error: 'Password reset failed' });
          
        });
}



const SubmitValidationForm = (props) => {
  const { error, handleSubmit, submitting } = props;
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5 tc"
    >

      <ThumbUp style={{height:100,width:100, color:'green'}}/>
       <p>An email was sent to you containing a link to reset your password. Please check your mailbox, if you do not see any email please check your spam folders as it might accidentally arrive there.</p>

      
      <legend className="f4 fw6 ph0 mh0 tc">Reset my password</legend>
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="email"
          component={renderField}
          label="Your email address"
        />
        <Field
          name="password"
          type="password"
          component={renderField}
          label="New password"
        />
        <Field
          name="password2"
          type="password"
          component={renderField}
          label="Confirm new password"
        />
        <Field
          name="token"
          component={renderField}
          label="The code you received by email"
        />
        
        {error && <strong>{error}</strong>}
        <div className="mt3">
          <ButtonLoader submitting={submitting} text="Reset" />
          
        </div>
      </form>
    </section>
  );
};

export default reduxForm({
  form: "resetPassword",
  validate
})(SubmitValidationForm);