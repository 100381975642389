import React from "react";
import { Field, reduxForm } from "redux-form";
import { SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import axios from "axios";
import ButtonLoader from "../../Helpers/ButtonLoader";
import {renderField, renderPassword, renderCheckbox} from "../../Helpers/renderField";
import ThumbUp from "@material-ui/icons/ThumbUp";

const submitCode = async (values, dispatch, props) => {
  try {
    await axios.get("/api/verifycode/"+values.activationCode);
    window.location.href="/gallery"
  } catch (error) {
    window.log(error.response)
    if (!error.response.data.success)
      throw new SubmissionError({
        activationCode: "Wrong validation code",
      });
  }
};



export const validate = values => {
  const errors = {};
  if (!values.activationCode) {
    errors.password = "Enter the code you received by email";
  }
  
  return errors;
};

const ValidateEmail = props => {
  window.log(props);
  const { error, handleSubmit, submitting } = props;
  
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5 tc"
    >
    <ThumbUp style={{height:100,width:100, color:'green'}}/>
      <h1>Thank you for registering </h1>

      <p>An email with a code was sent to you to confirm your email address.  </p>
      <form onSubmit={handleSubmit(submitCode)}>
        <Field
          name="activationCode"
          type="text"
          component={renderField}
          label="Activation code"
        />
       {error && <strong>{error}</strong>}
        
        <div className="mt3">
          <ButtonLoader submitting={submitting} text="Verify my email" />
        </div>
        
      </form>
    </section>
    )
};

export default reduxForm({
  form: "ValidateEmail",
  validate
})(ValidateEmail);