import React, { Component } from "react";
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "../../axios.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "50px",
    height: "40%"
  }
};

class PDFCreator extends Component {
  constructor() {
    super();
    this.state = {
      url: null,
      generated: false
    };
  }

  async componentDidMount() {
    try {
      const res = await axios.get("/api/pdf?client=browser&month=" + this.props.month);
      this.setState({ generated: true, url: res.data });
    } catch (error) {}
  }

  /*afterOpenModal() {
    // references are now sync'd and can be accessed.
  }*/

  closeModal = () => {
    this.props.onClose();
  }

  

  render() {
    return (
      <div>
        <Modal isOpen={true} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles}>
          <div class="tc" style={{ height:"100%", width: "80%", margin: "0 auto", display:"flex", flexDirection:"column", justifyContent:"center" }}>
            

            {!this.state.generated && <p> Your album will be ready to download in a few seconds... Please keep this window open </p>}
            {this.state.generated && <a href={this.state.url} download> Click to download a preview! </a>}
            
          </div>
        </Modal>
      </div>
    );
  }
}

export default PDFCreator;