const validate = values => {
  const errors = {}
  
  if (!values.nickname) {
    errors.nickname = 'Required'
  }
  if (!values.fullName) {
    errors.fullName = 'Required'
  }
  
  if (!values.street) {
    errors.street = 'Required'
  } 

  if (!values.country) {
    errors.country = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  return errors
}

export default validate