import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./Header/index";
import { connect } from "react-redux";
import * as actions from "../actions";
import Landing from "./landing";
import Signup from "./Auth/Signup/";
import Login from "./Auth/Login";
import NoMatching from "./NoMatching"
import Footer from "./footer";
import Gallery from "./Gallery/index";
import AskResetPassword from "./Auth/ResetPassword/ask";
import ResetPassword from "./Auth/ResetPassword/reset";
import UserProfile from "./Auth/Profile/UserProfile";
import ValidateEmail from "./Auth/Profile/ValidateEmail";
import RecipientProfile from "./Recipient/EditRecipient/RecipientProfile";
import CreateRecipient from "./Recipient/CreateRecipient";
import Network from "./Recipient/Network/index"
import Payment from "./Payment/Payment"
import ActivityIndicator from './ActivityIndicator'
import background from './photography.png'
import { isIOS, isAndroid, isMobile } from "react-device-detect";

import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";



class App extends Component {

	

	componentDidMount() {
		if (isAndroid) window.location.href="https://play.google.com/store/apps/details?id=com.edicated.babushkam"
		if (isIOS) window.location.href="https://apps.apple.com/app/babushkam/id1473681816"
		this.props.init();
		document.title = "Babushkam"
		
	}


	render() {
		window.log("Rerender App");

		const auth = this.props.auth;
		const recipients = this.props.recipients;
		const network = this.props.network
		if (isMobile ) return (
			<BrowserRouter>
				<div>
				<Route path="/" component={Landing} />
				<Footer />
				</div>
			</BrowserRouter>	
		)
		switch (auth) {
			case null:
				return <ActivityIndicator/>;
			case false:
				return (
					<BrowserRouter>
						<div data-name="component" style={{background:`url(${background})`}}>
						<div className="min-vh-100">
							<Alert stack={{ limit: 1 }} />
							<Switch>
							<Route exact path="/login" render={(props) => <Login {...props} {...this.props}/>} />
							<Route exact path="/reset-password" component={AskResetPassword} />
							<Route exact path="/set-password" component={ResetPassword} />
							<Route exact path="/signup" render={(props) => <Signup {...props} {...this.props}/>} />
							<Route exact path="/" component={Landing} />
							<Route component={NoMatching} />
							</Switch>
						</div>
							<Footer />

						</div>
					</BrowserRouter>
				);
			default: {
				
				if (recipients && network) {
					return (
						<BrowserRouter>
							<div data-name="component" style={{background:`url(${background})`}}>
							<div className="min-vh-100">
								<Alert stack={{ limit: 1 }} />
								
								<Route path="/" render={(props) => <Header {...props} {...this.props}  />} />
								<Switch>
								{this.props.auth.verificationCode && <Route path="/" render={(props) => <ValidateEmail {...props}  {...this.props}/>} />}
								{this.props.recipients.length===0 && <Route path="/" render={(props) => <CreateRecipient {...props} {...this.props}  />} /> }
								<Route exact path="/" render={(props) => <Gallery {...props} {...this.props}/>} />
								<Route exact path="/subscription" render={(props) => <Payment {...props} {...this.props}  />} /> 
								<Route exact path="/new-recipient" render={(props) => <CreateRecipient {...props} {...this.props}  />} />
								
								<Route exact path="/gallery/:month?" render={(props) => <Gallery {...props} {...this.props}/>} />
								
								<Route exact path="/profile" component={UserProfile} />
								<Route exact path="/recipient" render={(props) => <RecipientProfile {...props} {...this.props} />} />
								<Route exact path="/network" render={(props) => <Network {...props} {...this.props} />} />
								<Route render={(props) => <NoMatching {...props}  />} />
								</Switch>
							</div>
							<Footer />
							</div>
						</BrowserRouter>
					);
				} 	
				else return <ActivityIndicator/>
			}
		}
	}
}

function mapStateToProps(state) {
	//window.log("mapping")
	return {
		photos: state.album.photos,
		activePhoto: state.activePhoto,
		album: state.album,
		auth: state.auth,
		recipients: state.recipients,
		network: state.network,
		recipient: state.auth && state.auth._activeRecipient && state.recipients.find(r => r._id.toString() === state.auth._activeRecipient.toString())
	};
}

export default connect(
	mapStateToProps,
	actions
)(App);