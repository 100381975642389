import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { connect } from "react-redux";
import DropdownList from "react-widgets/lib/DropdownList";
import "react-widgets/dist/css/react-widgets.css";
import AvatarPicker from "./AvatarPicker";
import { SubmissionError } from "redux-form";
import axios from "axios";
import Alert from "react-s-alert";
import ButtonLoader from "../../Helpers/ButtonLoader"
import {renderField, renderDrop, renderPassword} from "../../Helpers/renderField";

// styles
const $label = 'db fw6 lh-copy f6" for="email-address';
const $btn = "br4 pv2 input-reset bg-transparent grow pointer dib shadow-5 no-outline";

const submit = async (values, dispatch, props) => {
  try {
    await axios.post("/api/updateUser", { values });
    Alert.success("Profile successfully updated", {
      position: "top-right",
      effect: "slide",
      timeout: 5000
    });
    props.history.push("/profile");
  } catch (error) {
    if (error.response.status === 401)
      throw new SubmissionError({
        username: error.response.data
      });
  }
};


let ProfileForm = props => {
  const { error, handleSubmit, submitting } = props;
  const avatar = props.initialValues.avatar
    ? props.initialValues.avatar
    : process.env.REACT_APP_IMAGE_CDN + "/static/default-avatar.png";

  window.log(props);
  return (
    <section
      data-name="component-info"
      className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5 w-80"
    >
      <h1 className="serif tracked ma0 mb4 pv3 f2 tc">Edit your profile</h1>
      <h3>Account information</h3>
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="username"
          type="text"
          component={renderField}
          label="Email"
        />
        <Field
          name="password"
          type="password"
          component={renderPassword}
          label="New password"
          placeholder="Enter a new password"
          customClass="dib w-40 v-top"
        />
        <Field
          name="password2"
          type="password"
          component={renderField}
          label="Confirm password"
          placeholder="Confirm your new password"
          
        />

        <Field
          name="language"
          component={renderDrop}
          options={[{ label: "English", value: "en" }]}
          className={$btn}
          label="Language"
        />
        <h3>Profile picture</h3>

        <AvatarPicker
          type="user"
          avatarUrl={avatar}
          customClass={"bg-green white w-100 f6 " + $btn}
          userId={props.initialValues._id}
        />

        <h3>Personal information</h3>
        <Field
          name="phone"
          type="text"
          component={renderField}
          label="Phone number"
          placeholder="Enter your phone number"
        />
        
        <Field
          name="firstName"
          type="text"
          component={renderField}
          label="First name"
          placeholder="Your first name"
          customClass="dib w-40 v-top"
        />
        <Field
          name="lastName"
          type="text"
          component={renderField}
          label="Last name"
          placeholder="Your last name"
          customClass="dib mh4 w-40 v-top"
        />
        {error && <strong>{error}</strong>}
        <div className="mt4 tc">
          <ButtonLoader submitting={submitting} text="Save" />
        </div>
      </form>
    </section>
  );
};

ProfileForm = reduxForm({
  form: "ProfileForm",
  validate
})(ProfileForm);

ProfileForm = connect(
  state => ({
    initialValues: {
      _id: state.auth._id,
      username: state.auth.username,
      language: state.auth.language,
      avatar: state.auth.avatar,
      firstName: state.auth.firstName,
      lastName: state.auth.lastName
    }, // pull initial values from account reducer
    enableReinitialize: true
  }),
  null
)(ProfileForm);

export default ProfileForm;