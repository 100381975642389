import axios from "axios";

const Uppy = require("uppy/lib/core");
const GoogleDrive = require("uppy/lib/plugins/GoogleDrive");
const Instagram = require("uppy/lib/plugins/Instagram");
const Dropbox = require("uppy/lib/plugins/Dropbox");
const Webcam = require("uppy/lib/plugins/Webcam");
const AwsS3 = require("uppy/lib/plugins/AwsS3");

export const createUppy = (type, props, completeCallback) => {
	const autoProceed = type === "photo" ? false : true;
	const maxNumberOfFiles = type === "photo" ? 50 : 1;
	const avatarPath = type !== "photo" ? "avatar/" : "";
	const id = type === "photo" ? props.auth._id : props.userId
	const uppy = Uppy({
		meta: { type: type },
		autoProceed: autoProceed,
		restrictions: {
			allowedFileTypes: ["image/*"],
			maxFileSize: 10000000,
			maxNumberOfFiles: (props.album) ? props.album.limit - props.album.photos.length:1
		},
		onBeforeUpload: files => {
			const updatedFiles = Object.assign({}, files);
			Object.keys(updatedFiles).forEach(fileId => {
				updatedFiles[fileId].name =
					avatarPath +
					id +
					"/" +
					Date.now() +
					"/" +
					Math.random()
						.toString(36)
						.substring(7) +
					"." +
					updatedFiles[fileId].name.split(".").pop(); //remove special characters from name
			});
			return updatedFiles;
		}
	});
	/*uppy.use(GoogleDrive, {
		serverUrl: process.env.REACT_APP_UPPY_SERVER_URL
	});
	uppy.use(Instagram, {
		serverUrl: process.env.REACT_APP_UPPY_SERVER_URL
	});
	uppy.use(Dropbox, { serverUrl: "https://server.uppy.io" });*/
	uppy.use(Webcam, {
		modes: ["picture"],
		mirror: true
	});
	uppy.use(AwsS3, {
		serverUrl: process.env.REACT_APP_UPPY_SERVER_URL,
		id: "AwsS3"
	});

	uppy.on("complete", async result => {
		// when adding photos to album
		if (type === "photo") {
			const toAdd = result.successful;
			const promises = [];
			toAdd.forEach(file => {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(file.data);
				promises.push(
					new Promise(resolve => {
						fileReader.onload = () => {
							const image = new Image();
							image.onload = () => {
								resolve(
									(file["dimensions"] = {
										width: image.width,
										height: image.height
									})
								);
							};

							image.src = fileReader.result;
						};
					})
				);
			});
			await Promise.all(promises);

			completeCallback(toAdd);
		}
		//when changing avatar
		else {
			const path =
				type === "user" ? "updateAvatar" : "updateRecipientAvatar";
			axios
				.post("/api/" + path, { avatar: result.successful[0].name })
				.then(response => {
					completeCallback(result);
				})
				.catch(error => {});
		}
	});

	return uppy;
};