import React, { Component } from "react";
import PhotoGallery from "react-photo-gallery";
import Photo from "./Photo";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import Lightbox from "react-images";
import PhotoEditor from "../PhotoEditor/index";
import _ from "lodash";
import NoPhoto from "./noPhoto";
import MonthPicker from "./MonthPicker";
import ActivityIndicator from "../ActivityIndicator";
import { Link } from "react-router-dom";
import Pageview from "@material-ui/icons/Pageview";
import PDFPreview from "../PDF"


const SortablePhoto = SortableElement(Photo);
const SortableGallery = SortableContainer(({ photos, openLightbox }) => {
  return (
    <PhotoGallery
      photos={photos}
      columns={Math.floor((window.innerWidth * 0.96) / 260)}
      ImageComponent={SortablePhoto}
      
    />
  );
});

class Gallery extends Component {
  constructor() {
    super();
    this.onSortEnd = this.onSortEnd.bind(this);
    this.state = {
      photos: [],
      currentImage: 0,
      imgToEdit: 0,
      height: 0,
      onOngoingUpload: false,
      readOnly: true,
      selectedMonth: new Date().toISOString().slice(0, 7),
      loading: true,
      previewOpen:false
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.uploadStatus = this.uploadStatus.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    if (this.state.readOnly) return;
    this.setState(
      {
        photos: arrayMove(this.state.photos, oldIndex, newIndex)
      },
      function() {
        this.props.reorderPhotos(oldIndex, newIndex);
      }
    );
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: _.findIndex(this.props.album.photos, function(p) {
        return event.target.getAttribute("src").includes(p.src);
      }),
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }

  componentDidMount() {
    window.log("Mounting gallery");
    const { month } = this.props.match.params
      ? this.props.match.params
      : new Date().toISOString().slice(0, 7);

    this.props.fetchAlbum(month).then(() => {
      const { photos } = this.props.album;
      this.setState({
        loading: false,
        photos: photos,
        readOnly:
          this.props.album.month !== new Date().toISOString().slice(0, 7),
        selectedMonth: month
      });
    });
  }

  componentDidUpdate() {
    const { photos } = this.props.album;
    // only update when pictures are added or removed
    if (
      this.state.photos.length !== photos.length &&
      !this.state.onOngoingUpload
    ) {
      this.setState({
        photos: photos
      });
    }

    // if number of pictures are similar but some pictures changed
    else if (
      this.state.photos.length === photos.length &&
      _.intersectionWith(this.state.photos, photos, _.isEqual).length <
        photos.length
    ) {
      this.setState({
        photos: photos
      });
    }
    const d = new Date().toISOString().slice(0, 7);
    if (this.state.readOnly !== (this.props.album.month !== d))
      this.setState({ readOnly: this.props.album.month !== d });
  }

  uploadStatus(status) {
    this.setState({ onOngoingUpload: status });
  }

  changeMonth(month) {
    this.setState({ selectedMonth: month }, () => this.props.fetchAlbum(month));
  }

  render() {
    if (this.state.loading) return <ActivityIndicator withoutBackground={true}/>;
    const photos = this.state.photos;
    window.log("Gallery rerender");
    const d = new Date().toISOString().slice(0, 7);
    switch (photos.length) {
      case 0:
        return (
          <div
            style={{ width: "96%", margin: "auto", marginBottom: "5px" }}
            className="ph6"
          >
            <MonthPicker
              {...this.props}
              month={this.state.selectedMonth}
              changeMonth={month => this.changeMonth(month)}
            />
            <NoPhoto
              onOngoingUpload={this.uploadStatus}
              readOnly={d !== this.props.album.month}
              changeMonth={month => this.changeMonth(month)}
              {...this.props}
            />
          </div>
        );

      default:
        return (
          <div
            style={{ width: "96%", margin: "auto", marginBottom: "50px" }}
            className="ph6"
          >
            {this.state.previewOpen && <PDFPreview month={this.state.selectedMonth} onClose={() => this.setState({previewOpen:false})}/> }
            <div className="mv4">
              <MonthPicker
                {...this.props}
                month={this.state.selectedMonth}
                changeMonth={month => this.changeMonth(month)}
              />

              <SortableGallery
                axis={"xy"}
                photos={photos}
                onSortEnd={this.onSortEnd}
                distance={2}
                openLightbox={this.openLightbox}
              />


              <PhotoEditor {...this.props}/>
            </div>
            
              <p className="mt6 pointer pa2 tc" onClick={() => this.setState({previewOpen:true})}><span className="ba br-pill grow pa2" ><Pageview className="mh2 v-mid"/>Preview this album</span></p>
          </div>
        );
    }
  }
}


export default Gallery