import { FETCH_USERS, INIT } from '../actions/types';

export default function(state = null, action){
	switch (action.type) {
		case FETCH_USERS:
			return action.payload || false;
		case INIT:
			return action.payload.network || false
		default:
			return state;
	}
}