import React, { Component } from "react";
import "./overlay.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { selectPhoto, deletePhotos } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Image from "react-graceful-image";

function getOrdinalNum(n) {
  return (
    <span>
      {n}
      <sup>{n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : ""}</sup>
    </span>
  );
}


class Photo extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.state = { src: "" };
    this.ownerAvatar = null;
  }

  componentDidMount() {
    window.log(this.props);
    this.setState({
      src: require("../Helpers/getURL")(this.props.photo.src, {
        resize: {
          width: 600,
          fit: "cover"
        }
      }),
      placeholder: require("../Helpers/getURL")(this.props.photo.src, {
        resize: {
          width: 50,
          fit: "cover"
        }
      }),
      ownerAvatar: this.props.network.find(r => r._id === this.props.photo._addedBy).avatar
    });
  }

  handleClick(event) {
    this.props.onClick(event, { photo: this.props.photo, index: this.props.index });
  }

  deletePhoto(photo) {
    this.props.deletePhotos([photo]);
  }

  render() {
    const imgStyle = { margin: this.props.margin };
    if (this.props.direction === "column") {
      imgStyle.position = "absolute";
      imgStyle.left = this.props.left;
      imgStyle.top = this.props.top;
    }

    const imgWithClick = { cursor: "pointer" };
    const photo = this.props.photo;
    var ellipsis = require("text-ellipsis");
    return (
      <div className="container" style={{ width: photo.width + "px", height: photo.height + "px" }}>
        <div className="grow" onClick={() => this.props.selectPhoto(photo)}>
          <Image
            style={this.props.onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            src={this.state.src}
            width={photo.width}
            height={photo.height}
            alt="img"
            className="image br3 shadow-5"
            placeholderColor={`#eee url(${this.state.placeholder}) no-repeat center/110%`}
            noLazyLoad
            /* onError={(e)=>{e.target.onerror = null; e.target.src='https://s3.eu-central-1.amazonaws.com/babushka/'+photo.src}}*/
          />
          {photo.caption && <div className="overlay br3">{ellipsis(photo.caption)}</div>}
        </div>

        <div className="overlayTop">
          {photo.date && (
            <span className="br4 h3 w3 dib tc dtc bg-white-40 v-mid fl black" style={{ lineHeight: "60px" }}>
              {getOrdinalNum(new Date(photo.date).getDate())}
            </span>
          )}

          {photo._addedBy.toString() === this.props.auth.id &&
            this.props.month === new Date().toISOString().slice(0, 7) && [
              <DeleteIcon className="iconImage pointer" onClick={() => this.props.deletePhotos([photo])} />,
              <EditIcon className="iconImage pointer" onClick={() => this.props.selectPhoto(photo)} />
            ]}

          {photo._addedBy.toString() !== this.props.auth.id && (
            <img className="br-100 ba b--black-10 h2 w2" src={this.state.ownerAvatar} alt="Owner avatar" />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //window.log("mapping")
  return { auth: state.auth, network: state.network, month: state.album.month };
}

function mapDispatchToProps(dispatch) {
  //Whenever selectBook is called the result should be passed to all of our reducers
  return bindActionCreators({ selectPhoto: selectPhoto, deletePhotos: deletePhotos }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Photo);