import React from "react";
import Modal from "react-modal";
import RotateIcon from "@material-ui/icons/RotateLeft";
import DeleteIcon from "@material-ui/icons/Delete";
import "./photoEditor.css";
import { bindActionCreators } from "redux";
import { updatePhotos, deletePhotos } from "../../actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ImageLoader from 'react-loading-image'
import ButtonLoader from "../Helpers/ButtonLoader"
import { RingLoader } from "react-spinners";
import { css } from 'react-emotion';
import _ from "lodash"

const override = css`
    display: block;
    margin: auto;
    marginTop: '150px';
    border-color: red;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "900px"
  }
};


const thumbnailSize = 200;

Modal.setAppElement("#root");

class AfterUpload extends React.Component {
  constructor() {
    super();

    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.rotate = this.rotate.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.save = this.save.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.getSrc = this.getSrc.bind(this)
    this.state = {
      isOpen: false,
      photos: [],
      newPhotos: [],
      rotations: [],
      deleted: [],
      urls: [],
      isSaving: false,
      needUpdate: false
    };
  }

  componentDidMount() {
    window.log(this.props.photos.length)
    window.log(this.props.newPhotos.length)
    this.setState({
      isOpen: this.props.isOpen,
      newPhotos: this.props.newPhotos,
      photos: _.takeRight(this.props.photos, this.props.newPhotos.length),
      deleted: [],
      rotations: Array.apply(null, Array(this.props.newPhotos.length)).map(
        Number.prototype.valueOf,
        0
      )
    });
  }

  componentDidUpdate() {
    if (this.state.photos.length === 0) {
      this.closeModal();
    }
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    if (this.state.deleted.length > 0)
      this.props.deletePhotos(this.state.deleted);

    this.setState({ photos: [], rotations: [], deleted: [] });
    this.props.onClose();
  }

  deletePhoto(index) {
    this["comment" + index].value = "";
    this.setState({
      deleted: [...this.state.deleted, this.state.photos[index]],
      photos: this.state.photos.filter((_, i) => i !== index),
      rotations: this.state.rotations.filter((_, i) => i !== index)
    });
  }

  rotate(index) {
    var newRotations = this.state.rotations;
    newRotations[index] = this.state.rotations[index] + 90;
    if (newRotations[index] > 270) newRotations[index] = 0;
    this.setState({ rotations: newRotations, needUpdate:true });
  }

  updateCaption(index) {
    var self = this;
    return function(e) {
      const photos = self.state.photos;
      photos[index].caption = e.target.value;
      self.setState({ photos: photos, needUpdate:true });
    };
  }

  handleDate(index, date) {
    const photos = this.state.photos;
    photos[index].date = date !== null ? date.format() : null;
    this.setState({ photos: photos, needUpdate:true });
  }

  getSrc(size, index){
    const rotation = this.state.rotations[index]
    return require("../Helpers/getURL")(this.state.photos[index].src, {
        resize: {
          width: size,
          height:size,
          fit: "inside"
        },
        rotate:rotation
      })
  }

  save() {
    this.setState({isSaving: true})
    var self = this;
    var photos = this.state.photos;
    var updates = [];
    photos.forEach((photo, index) => {
      var newPhoto = _.clone(photo);
      if (this.state.rotations[index] > 0 || photo.caption || photo.date) {        
        // Revert height and width
        if (
          self.state.rotations[index] === 90 ||
          self.state.rotations[index] === 270
        ) {
          newPhoto.width = photo.height;
          newPhoto.height = photo.width;
        }

        updates.push({
          newPhoto: newPhoto,
          newPhotoUrl: this.getSrc("1200x800",index),
          wasTransformed: self.state.rotations[index] > 0
        });
      }
    });
    // Only call updates if changes were made
    if (this.state.needUpdate)
      this.props.updatePhotos(updates).then(() => {this.setState({isSaving: false});self.closeModal()});
    else self.closeModal()
    
    
  }

  render() {
    const photos = this.state.photos;
    var photoList = [];
    var self = this;
    photos.forEach(function(photo, index) {
      photoList.push(
        <div className="dt w-100 mv4" key={index}>
          <div className="dtc v-mid" style={{ width: "3%" }}>
            <RotateIcon onClick={() => self.rotate(index)} />
            <DeleteIcon
              className="mv4"
              onClick={() => self.deletePhoto(index)}
            />
          </div>
          <div className="dtc v-mid w-30">
            <ImageLoader
                  src={self.getSrc(thumbnailSize,index)}
                  loading={() => (<div><RingLoader className={override} color={'#19A974'} sizeUnit={"px"} size={100}/> <p className="tc">Processing...</p></div>)}
                  error={() => <div>Error</div>}
                  className="center db br3 v-mid"
              />
          </div>
          <div className="dtc v-mid w-60">
            <form className="black-80">
              <label className="f6 b db mb2">
                Description
                <span className="normal black-60">(optional)</span>
              </label>
              <textarea
                id={self["comment" + index]}
                name="comment"
                ref={ref => (self["comment" + index] = ref)}
                className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                aria-describedby="comment-desc"
                value={self.state.photos[index].caption}
                onChange={self.updateCaption(index)}
              />
              <label className="f6 b db mb2 fl mh2">
                Date
                <span className="normal black-60">(optional)</span>
              </label>
              <DatePicker
                selected={
                  self.state.photos[index].date
                    ? moment(self.state.photos[index].date)
                    : null
                }
                minDate={moment().startOf("month")}
                maxDate={moment().endOf("month")}
                onChange={value => self.handleDate(index, value)}
              />
            </form>
          </div>
        </div>
      );
    });

    return (
      <div>
        <Modal
          isOpen={true}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <div
            id="modalContainer"
            className="pl2 overflow-y-scroll"
            style={{ maxHeight: "80vh" }}
          >
            <h2>Edit or add comments</h2>
            {photoList}
          </div>
          <div className="mt4 dib mh2 w-100 pa4">
            <ButtonLoader onClick={() => this.save()} submitting={this.state.isSaving} text="Save changes" />
          </div>
        </Modal>
      </div>
    );
  }
}

export default AfterUpload;