import React from "react";
import { Field, reduxForm } from "redux-form";
import submit from "./submit";
import validate from "./validate";
import { connect } from "react-redux";
import { deleteRecipient } from "../../../actions";
import { bindActionCreators } from "redux";
import "react-widgets/dist/css/react-widgets.css";
import AvatarPicker from "../../Auth/Profile/AvatarPicker";
import Alert from "react-s-alert";
import { renderField, renderDrop } from "../../Helpers/renderField";
import ButtonLoader from "../../Helpers/ButtonLoader";
import Delete from "@material-ui/icons/Delete";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Search from "@material-ui/icons/Search";
import Collapsible from "react-collapsible";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const $btn = "br4 pv2 input-reset no-underline grow pointer dib shadow-5 no-outline";

const $input = "pa2 input-reset ba bg-transparent w-100";
const $label = "db fw6 lh-copy f6 ttu mv2";

function generateCountryList() {
  return require("country-list")
    .getData()
    .map(e => {
      return { label: e.name, value: e.code };
    });
}

const options = generateCountryList()
const albumLanguage = [{label:"English", value: "en"}, {label:"French", value: "fr"}]

class RecipientProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "", rendered: false, collapsed: false };
  }

  handleChange = address => {
    this.setState({ address });
  };

  componentDidMount() {
    const rec = this.props.recipients.find(r => r._id == this.props.auth._activeRecipient);
    if (rec.profileIsComplete) this.setState({ collapsed: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth._activeRecipient !== prevProps.auth._activeRecipient) this.setState({ collapsed: false });
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        window.log(results);
        this.setState({ collapsed: true });
        
        if (results[0].address_components.find(r => r.types.includes("route")))
          this.props.change("street", results[0].formatted_address.split(',')[0]);
        if (results[0].address_components.find(r => r.types.includes("postal_code")))
          this.props.change(
            "postalCode",
            results[0].address_components.find(r => r.types.includes("postal_code")).short_name
          );
        if (results[0].address_components.find(r => r.types.includes("locality")))
          this.props.change("city", results[0].address_components.find(r => r.types.includes("locality")).short_name);
        if (results[0].address_components.find(r => r.types.includes("country")))
          this.props.change("country", {
            label: results[0].address_components.find(r => r.types.includes("country")).long_name,
            value: results[0].address_components.find(r => r.types.includes("country")).short_name
          });
        if (results[0].address_components.find(r => r.types.includes("administrative_area_level_1")))
          this.props.change(
            "state",
            results[0].address_components.find(r => r.types.includes("administrative_area_level_1")).short_name
          );
        this.setState({ address: "" });
      })
      .catch(error => console.error("Error", error));
  };

  render() {
    
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    const title = this.props.recipient.nickname+"'s profile";
    const initialValues = this.props.initialValues;
    const canDelete =
      this.props.recipients.length > 1 &&
      !this.props.recipient.paymentCard &&
      this.props.recipient._admin === this.props.auth._id;
    return (
      <section data-name="component-info" className="mv4 mw5 mw7-ns center bg-light-gray pa3 ph5-ns br4 shadow-5">
        <p className="serif tracked ma0 mb4 pv3 f2 tc" style={{ margin: "auto" }}>
          {title}
        </p>

        <div style={{ margin: "auto" }}>
          <AvatarPicker
            userId={initialValues.id}
            type="recipient"
            avatarUrl={initialValues.avatar}
            customClass={"white f6 " + $btn}
          />
        </div>

        <form onSubmit={handleSubmit(submit)}>
          <Field
            name="nickname"
            type="text"
            component={renderField}
            placeholder="How should we call him/her ?"
            label="Nickname"
          />

          <Field
              name="language"
              component={renderDrop}
              placeholder="Language"
              label="Album language"
              customClass="dib v-top w-100"
              options={albumLanguage}
            />

          <h3 className="tc">Postal address</h3>
          <Field
            name="fullName"
            type="text"
            component={renderField}
            placeholder="Enter your recipient's full name"
            label="First and last name"
          />

          
          
          
          
            <Field
              name="street"
              type="text"
              component={renderField}
              placeholder="Street"
              customClass="dib w-60 v-top"
              label="Street name and street number"
            />


            <Field
              name="street2"
              type="text"
              component={renderField}
              placeholder="Apartment or unit number"
              customClass="dib mh4 w-30 v-top"
              label="Apartment/Suite/Unit"
            />

            <Field
              name="postalCode"
              type="text"
              component={renderField}
              placeholder="Postal code"
              customClass="dib w-30 v-top"
              label="Postal or ZIP code"
            />
            <Field
              name="city"
              type="text"
              component={renderField}
              placeholder="City"
              customClass="dib w-60 mh4 v-top"
              label="City"
            />
            <Field
              name="state"
              type="text"
              component={renderField}
              placeholder="State or Province"
              customClass="dib w-30 v-top"
              label="State / Province"
            />

            <Field
              name="country"
              component={renderDrop}
              placeholder="Country"
              label="Country"
              customClass="dib w-60 mh4 v-top"
              options={options}
            />
          {error && <strong>{error}</strong>}
          <div className="mt4 tc ">
            <ButtonLoader submitting={submitting} text="Save" />
          </div>
          
            <div className="mt2 tc ">
              <p onClick={async () => {await this.props.deleteRecipient(); this.props.history.push("/gallery")}} className={"bg-red white w-100 f4 " + $btn}>
                <Delete className="mr2 v-btm" />
                DELETE
              </p>
            </div>
         
        </form>
      </section>
    );
  }
}

function getInitialValue(state) {
  const rec = state.recipients.find(r => r._id == state.auth._activeRecipient);
  return {
    id: rec._id,
    language: rec.language,
    fullName: rec.fullName,
    nickname: rec.nickname,
    street: rec.address.street,
    street2: rec.address.street2,
    streetNumber: rec.address.streetNumber,
    postalCode: rec.address.postalCode,
    city: rec.address.city,
    state: rec.address.state,
    country: options.find(o => o.value===rec.address.country),
    avatar: rec.avatar
  };
}

function mapDispatchToProps(dispatch) {
  //Whenever selectBook is called the result should be passed to all of our reducers
  return bindActionCreators({ deleteRecipient: deleteRecipient }, dispatch);
}

RecipientProfile = reduxForm({
  form: "RecipientProfile",
  validate,
  enableReinitialize: true
})(RecipientProfile);

RecipientProfile = connect(
  state => ({
    initialValues: getInitialValue(state),
  }),
  mapDispatchToProps
)(RecipientProfile);

export default RecipientProfile;